import React, { useEffect, useRef, useState } from 'react'
import _ from "lodash";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Switch from "react-switch";
import Select from 'react-select';
import { useGlobalContext } from '../../../../GlobalContext';
import usePrevious from '../../../../../customHooks/usePrevious';
import useDispatch from '../../../../../customHooks/useDispatch';
import useDimension from '../../../../../customHooks/useDimension';

import { updateCompanyUser, addCompanyUser, resetCompanyError } from '../../../../../actions/company';
import InputField from '../../../../partials/form_fields/InputField';
import SelectItems from '../../../../partials/form_fields/SelectItems';
import ModalStandalone from '../../../../partials/ModalStandalone';

import selectStyle from '../../../../partials/form_fields/selectStyle'

export default function FormContact({ activities, user, isLoading, cancel }) {
  const [formData, setFormData] = useState({})
  const [optionsActivities, setOptionsActivities] = useState()
  const [selectedActivities, setSelectedActivities] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [presenceDays, setPresenceDays] = useState()

  const [context, dispatch] = useGlobalContext();
  const companyReducer = context.companyReducer;

  const wasLoading = usePrevious(isLoading);
  const dimension = useDimension();

  const [isEmailError, setIsEmailError] = useState(false);
  
  //ACTIONS
  const _updateCompanyUser = (data, id) => updateCompanyUser(dispatch, data, id);
  const _addCompanyUser = (data) => addCompanyUser(dispatch, data);
  const _resetCompanyError = useDispatch(resetCompanyError, dispatch);

  // HANDLE ERROR ON EMAIL
  useEffect(() => {
    setIsEmailError(/email/.test(companyReducer.error))
  }, [companyReducer.error])

  // SET ACTIVITIES OPTIONS
  useEffect(() => {
    let options = activities.map((d) => (
      { label: d.label, value: d.label }
    ))
    options.push({ label: "AUCUN", value: null })
    setOptionsActivities(options)
    // eslint-disable-next-line
  }, [])


  // RESET COMPANY ERROR WHEN UNMOUNT
  useEffect(() => {
    return () => {
      _resetCompanyError()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getDateStr = (date) => {
      const day = format(new Date(date), 'd MMMM', { locale: fr });
      return day;
    }
    const days = companyReducer.companyList.days.map((day) => ({ value: day.value, label: getDateStr(day.value) } ));
    setPresenceDays(days);
  }, []);


  // INIT ACTIVITIES
  // IF THE ARE AN USER
  useEffect(() => {
    if (!user) return;
    if (user.profile.activity) {
      let activities = user.profile.activity.map((d) => {
        if (d === null) {
          return { label: "AUCUN", value: null };
        } else {
          return { label: d, value: d };
        }
      });
      setSelectedActivities(activities);
    }
    // eslint-disable-next-line
  }, []);

  // CLOSE FORM CONTACTS AFTER SUBMIT
  useEffect(() => {
    if (wasLoading && !isLoading) {
      cancel();
    }
  }, [isLoading])

  // HANDLE IS READY TO SUBMIT
  useEffect(() => {
    
    let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i
    let data;
 
    // SUBMIT UPDATE USER
    if (user) {

      let sanitizeUser = user.email.split("//")[0];
      let lunch = null;
      if (user.lunch === true) lunch = 'Oui';
      else if (user.lunch === false) lunch = 'Non';

      let initialProfile = {
        profile: user.profile,
        email: sanitizeUser,
        presence: user.presence?.length > 0 ? 'Oui' : 'Non',
        lunch,
      }
      data = _.merge(
        {},
        initialProfile,
        formData
      );
    }

    // SUBMIT CREATE USER
    if (!user) {
      data = {
        profile: formData.profile,
        email: formData.email,
        presence: formData.presence,
        lunch: formData.lunch
      }
    }

    if (
      (data.profile &&
        (data.profile.lastName && data.profile.lastName !== "") &&
        (data.profile.firstName && data.profile.firstName !== "") &&
        (data.profile.function && data.profile.function !== "") &&
        (data.profile.activity && data.profile.activity.length > 0)
      ) && (
        data.email &&
        emailReg.test(data.email)
      ) && (
        (data?.presence === 'Oui' && data.lunch)
        || (data?.presence === 'Non')
      )) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

  }, [formData])

  function handleChange(val, error, field) {
    let updatedData = { ...formData }

    if (field === "profile.activity") {
      setSelectedActivities(val ? val : [])
    }

    if (field === 'presence' && val === 'Oui') {
      updatedData.lunch = null;
    } else if (field === 'presence' && val === 'Non') {
      delete updatedData.lunch;
    }

    _.set(updatedData, field, val);

    setFormData(updatedData)
  }

  function submit() {
    if (!isValid || companyReducer.isLoading) return;
    if (user) {
      submitUdpateUser()
    } else {
      submitCreateUser()
    }
  }

  function submitUdpateUser() {

    let initialData = {
      profile: user.profile,
      presence: user.presence
    }
    delete initialData.profile.activity

    var mergedData = _.merge(
      {},
      initialData,
      formData
    );

    mergedData.company = user?.company?.map((d) => d._id) || [];
    mergedData.profile.activity = selectedActivities.map((d) => d.value);

    if (typeof mergedData.presence === 'string' && mergedData.presence === 'Oui') {
      mergedData.presence = presenceDays.map((d) => d.value);
    } else if (typeof mergedData.presence === 'string' && mergedData.presence === 'Non') {
      mergedData.presence = [];
      mergedData.lunch = false;
    }

    if (mergedData.lunch === 'Oui') mergedData.lunch = true;
    else if (mergedData.lunch === 'Non') mergedData.lunch = false;

    // mergedData.lunch = mergedData.presence.length > 0;

    _updateCompanyUser(mergedData, user._id);
  }

  function submitCreateUser() {
    let obj = _.cloneDeep(formData)
    obj.company = companyReducer.company._id

    if (obj.profile.activity) {
      obj.profile.activity = obj.profile.activity.map((d) => d.value).filter(d => !!d);
    }
    obj.type = companyReducer.company.type;


    if (obj.lunch === 'Oui') obj.lunch = true;
    else if (obj.lunch === 'Non') obj.lunch = false;

    if (obj.presence === 'Oui') {
      obj.presence = presenceDays.map((d) => d.value);
    } else {
      obj.presence = [];
      obj.lunch = false;
    }

    // obj.lunch = obj.presence.length > 0;
    console.log(obj);
    _addCompanyUser(obj);
  }

  const getPresenceValue = () => {
    let value = null;
    if (formData?.presence) value = [formData?.presence];
    if (!formData?.presence && Array.isArray(user?.presence) && user?.presence.length > 0) value = ['Oui'];
    if (!formData?.presence && Array.isArray(user?.presence) && user?.presence.length === 0) value = ['Non'];
    return value;
  };

  const getLunchValue = () => {
    let value = null;
    if (formData?.lunch) value = [formData?.lunch];
    if (formData?.lunch === undefined && user?.lunch === true ) value = ['Oui'];
    if (formData?.lunch === undefined && user?.lunch === false) value = ['Non'];
    return value;
  };

  return (
    <MobileWrapper isMobile={dimension.width < 767}>
      <section className={dimension.width > 767 ? "module-contact" : "module-contact mobile"}>

        {dimension.width < 767 ?
          <h2>
            Inscription d’un collaborateur
          </h2> : null
        }
        <InputField
          id={user ? "lastName_" + user._id : "lastName"}
          value={user ? user.profile.lastName : ""}
          title={"Nom"}
          placeholder={""}
          type={"string"}
          textTransform={"uppercase"}
          required={true}
          disabled={false}
          handleChange={(val, error) => handleChange(val, error, "profile.lastName")}
        />
        <InputField
          id={user ? "firstName_" + user._id : "firstName"}
          value={user ? user.profile.firstName : ""}
          title={"Prénom"}
          placeholder={""}
          type={"string"}
          textTransform={"capitalized"}
          required={true}
          disabled={false}
          handleChange={(val, error) => handleChange(val, error, "profile.firstName")}
        />
        <InputField
          id={user ? "function_" + user._id : "function"}
          value={user ? user.profile.function : ""}
          title={"Fonction"}
          placeholder={""}
          type={"string"}
          textTransform={"uppercase"}
          required={true}
          disabled={false}
          maxLength={"30"}
          handleChange={(val, error) => handleChange(val, error, "profile.function")}
        />
        <div className="container-email">
          <InputField
            id={user ? "email_" + user._id : "email"}
            value={user ? user.email.split("//")[0] : ""}
            title={"Email"}
            placeholder={""}
            type={"string"}
            format={"email"}
            required={true}
            disabled={user ? true : false}
            handleChange={(val, error) => handleChange(val, error, "email")}
          />
          {isEmailError && companyReducer.error ?
            <p className="warn">{companyReducer.error}</p> : null
          }
        </div>
        <div className="container-input">
          <label>Lieu de vente<span className="required">*</span></label>
          <Select
            onChange={(val) => handleChange(val, null, "profile.activity")}
            options={optionsActivities}
            value={selectedActivities}
            isMulti={true}
            isSearchable={false}
            styles={selectStyle}
          />
        </div>
        <div className='rowPresence'>
          <div className="container-input container-select-items">
            <label>Présence sur le salon<span className="required">*</span></label>
            <SelectItems
              items={['Oui', 'Non']}
              selectedItems={getPresenceValue()}
              uniq={true}
              handleChange={(val) => handleChange(val, false, "presence")}
            />
          </div>
          {(getPresenceValue()?.length > 0 && getPresenceValue()?.[0] === 'Oui') && (
            <div className="container-switch">
              <p>
                Présence au déjeuner
                <span className="required">*</span>
              </p>
              <SelectItems
                items={['Oui', 'Non']}
                selectedItems={getLunchValue()}
                uniq={true}
                handleChange={(val) => handleChange(val, false, "lunch")}
              />
            </div>
          )} 
        </div>
        <div className="container-button">
          <button className="secondary" onClick={cancel}>Fermer</button>
          <button className={!isValid ? "disabled" : ""} onClick={() => submit()}>
            {user && user._id ?
              "Modifier" : "Ajouter"
            }
            {companyReducer.isLoading ?
              <div className="loader"></div> : null
            }
          </button>
        </div>
        {!isEmailError && companyReducer.error ?
          <p className="warn">{companyReducer.error}</p> : null
        }
      </section>
    </MobileWrapper>
  )
}

const MobileWrapper = ({ isMobile, wrapper, children }) => {
  return (
    isMobile ?
      <div className="container-mobile">
        <div className="overlay-module-contact"></div>
        {children}
      </div> : children
  )
}

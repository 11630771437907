import React, { useEffect }  from 'react';
import { useGlobalContext } from '../../../GlobalContext';
import { getVisits, resetVisits } from '../../../../actions/visits';
import { format }  from 'date-fns';
import { AiOutlineScan, AiOutlineShoppingCart } from 'react-icons/ai';
import Loader from '../../../partials/Loader';
import styles from './visits.module.scss';

export const downloadVisits = async (id) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      responseType: 'blob',
      exhibition: `${localStorage.getItem('exhibition')}`,
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/visit/export/company/${id}`, config);
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'visits.xlsx';
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const Visits = () => {
  const [context, dispatch] = useGlobalContext();
  const { authReducer, visitReducer } = context;
  const { user } = authReducer;
  const { visits = [], isLoading } = visitReducer;
  const company = user?.company || null;

  // ACTIONS
  const _getVisits = (companyId) => getVisits(dispatch, companyId);
  const _resetVisits = () => resetVisits(dispatch);

  useEffect(() => {
    if (!company) return null;
    _getVisits(company);
    //return () => {
      //_resetVisits();
    //}
  }, [company]);

  return (
    <div className={styles.visits}>
      {isLoading && <Loader />}
      <div className="content large">
        <div className={styles.header}>
          <h1>Mes visites</h1>
          <button onClick={() => downloadVisits(company)}>Exporter les visites</button>
        </div>
        <ul className={styles.list}>
          {visits.map((visit) => (
            <li className={styles.visit} key={visit._id}>
              <div className={styles.shoppingCart}>
                <AiOutlineShoppingCart size={20}/>
              </div>
              <div className={styles.information}>
                <p className={styles.name}>{visit.magasin}</p>
                <p className={styles.userName}>{visit.userMagasin.firstName} {visit.userMagasin.lastName}</p>
              </div>
              <p className={styles.date}>
                <AiOutlineScan size={20}/>
                <span>
                  {format(new Date(visit.createdAt), 'dd/MM/yy')}
                  &nbsp;à&nbsp;{format(new Date(visit.createdAt), 'HH:mm')}
                </span>
                </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Visits;
import React, { useState, useEffect, useContext } from 'react';
import _ from "lodash";
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../../GlobalContext';
import { formatDataShowrooms } from '../helpers'
import { setFormShowroom, createShowroom, updateShowroom } from '../../../../../actions/showroom';
import Dimensions from './Dimension';
import ListOptions from './ListOptions';
import Recapitulatif from '../../../../partials/StandRecapitulatif';
import SupportContact from '../../../../partials/SupportContact';

export default function Form({ isLoading, ...props }) {
  const [ context, dispatch ] = useGlobalContext();
  const { authReducer, showRoomReducer, companyReducer } = context;
  const history = useHistory();

  const { exhibition } = authReducer;
  const [ validation , setValidation ] = useState({});
  const [ errorsOptions , setErrorsOptions ] = useState({});

  //ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch,data)
  const _createShowroom = (data) => createShowroom(dispatch,data);
  const _updateShowroom = (data,id) => updateShowroom(dispatch,data,id);
  
  /// INIT FORMDATA WITH COMPANYREDUCER AND AUTHREDUCER
  useEffect(() => {
    if(!companyReducer.company || !authReducer.user) return;
    let updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData.company = companyReducer.company._id;
    updatedData.user = authReducer.user._id;
    _setFormShowroom(updatedData);
  }, [
    companyReducer.company,
    authReducer.user,
  ]);

  useEffect(()=>{
    if(history.location.pathname !== "/stand/form"){
      let updatedData = _.cloneDeep(showRoomReducer.formData);
      _setFormShowroom(updatedData);
    }
  },[history.location.pathname]);

  // VALIDATE
  useEffect(() => {
    if(props.isLoading) return;

    const obj = {};
    if (
      exhibition?.showroomOption?.displayShowroomSize &&
      !showRoomReducer?.formData?.dimension
    ) {
      obj.dimension = "Vous n'avez pas indiqué les dimensions du stand"
    }

    setValidation(obj);

  }, [
    props.isLoading,
    showRoomReducer?.formData?.dimension,
    exhibition?.showroomOption?.displayShowroomSize,
  ]);
  

  function submit(){
    const errorInOptions = Object.keys(errorsOptions).length > 0;
    if (errorInOptions) return;

    // UPDATE CREATE SHOWROOM
    let showroomData = formatDataShowrooms(showRoomReducer.formData);

    if(!showRoomReducer.formData._id){
      _createShowroom(showroomData)
    }else{
      _updateShowroom(showroomData,showRoomReducer.formData._id)
    }
  }

  function renderSubmit(){

    let totalPrice = showRoomReducer?.formData?.negotiatedPrice;
    if (
      showRoomReducer?.formData?.option?.chair
      && exhibition.showroomOption.chairs.isAvailable
    ) {
      totalPrice += showRoomReducer?.formData?.option.chair * exhibition.showroomOption.chairs.price;
    }
    if (
      showRoomReducer?.formData?.option?.fridgeLength
      && exhibition.showroomOption.fridge.isAvailable
      && !exhibition.fridgeProvided
    ) {
      totalPrice += showRoomReducer?.formData?.option.fridgeLength * exhibition.showroomOption.fridge.price;
    }
    if (showRoomReducer?.formData?.option?.power
      && exhibition.showroomOption.power.isAvailable
    ) {
      totalPrice += showRoomReducer?.formData?.option.power * exhibition.showroomOption.power.price;
    }

    const errorInOptions = Object.keys(errorsOptions).length > 0;

    return (
      <>
      {showRoomReducer?.formData?.negotiatedPrice && (
        <div className='price-with-options'>
          {errorInOptions && (
            <div className='errors'>
              <p>Veuillez compléter les options</p>
            </div>
          )}
          <p>
            <span>Nouveau prix avec options&nbsp;:&nbsp;</span>
            <span>{totalPrice} €</span>
          </p>
        </div>
      )}
      { (Object.values(validation).length > 0) ?
        null :
        <div className='container-submit'>
          <button onClick={submit}
            className={
              errorInOptions ? 'disabled' : (showRoomReducer.isLoading ? 'loading' : "")
            }
          >
            {showRoomReducer.formData._id ? 'Modifier mon stand' : 'Valider mon stand'}
            {showRoomReducer.isLoading ?
              <div className="loader"></div> : null
            }
          </button>
        </div>
      }
      </>
    )
  }

  return(
    <div className="step step1">
      <Recapitulatif />
      {exhibition?.showroomOption?.displayShowroomSize && <Dimensions/>}
      <ListOptions isLoading={isLoading} handleErrorsOptions={setErrorsOptions} />
      {renderSubmit()}
      <div className="container-support">
        <SupportContact pageForm={true}/>
      </div>
    </div>
  )

}

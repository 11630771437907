import React, { useState, useEffect, useMemo } from 'react';
import _ from "lodash";
import { NavLink, useParams } from "react-router-dom";
import Select from 'react-select';
import { MdDeleteForever } from 'react-icons/md';
import { useGlobalContext } from '../../../GlobalContext';
import {
  getProductByID,
  postProduct,
  putProduct,
  resetSelectedProduct,
  resetErrorProducts,
  deleteProduct,
} from '../../../../actions/products';
import TooltipInfo from '../../../partials/TooltipInfo';
import InputField from '../../../partials/form_fields/InputField';
import selectStyle from '../../../partials/form_fields/selectStyle';
import InputImage from '../../../partials/form_fields/InputImage';
import IconDiscount from '../../../../assets/images/icons/icon-discount.svg'
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg'
import IconPunch from '../../../../assets/images/icons/icon-punch.svg'
import styles from './edit-product.module.scss';

const optionsDelivery = [
  { label: 'Centrale', value: 'Centrale' },
  { label: 'Direct', value: 'Direct' }
];

const optionsType = [
  { label: 'Carton', value: 'Carton' },
  { label: 'Box', value: 'Box' }
];

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${process.env.REACT_APP_API_URL}/file`, {
    method: 'POST',
    headers: {
      "Authorization": localStorage.getItem('token'),
      // "Content-Type": "multipart/form-data",
    },
    body: formData // This is your file object
  });
  return response;
};

const deleteFile = async (fileId) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/file/${fileId}`, {
    method: 'DELETE',
    headers: {
      "Authorization": localStorage.getItem('token'),
    },
  });
  return response;
};

const EditProduct = ({ history }) => {
  const params = useParams();
  const [context, dispatch] = useGlobalContext();
  const [formData, setFormData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [init, setInit] = useState(false);
  const [customerBenefit, setCustomerBenefit] = useState();
  const {
    companyReducer,
    productsReducer,
  } = context;
  const { product, isLoading, error } = productsReducer;

  //ACTIONS
  const _getProductByID = (id) => getProductByID(dispatch, id);
  const _postProduct = (product) => postProduct(dispatch, product);
  const _putProduct = (product) => putProduct(dispatch, product);
  const _deleteProduct = (id) => deleteProduct(dispatch, id);
  const _resetSelectedProduct = () => resetSelectedProduct(dispatch);
  const _resetErrorProducts = () => resetErrorProducts(dispatch);
  
  useEffect(() => {
    if (!params.id) {
      setInit(true);
      return;
    };
    _getProductByID(params.id);
  }, [params.id]);

  useEffect(() => {
    return () => {
      _resetSelectedProduct();
      _resetErrorProducts();
    }
  }, []);

  useEffect(() => {
    if (!product) return;

    setFormData({
      ref: product.ref,
      brand: product?.brand || '',
      gencod: product.gencod,
      description: product.description,
      type: product.type,
      codelec: product?.codelec || '',
      packing: product.packing,
      price: product.price,
      pricePA: product?.pricePA || '',
      tva: product.tva,
      pricePABrut: product.pricePABrut,
      delivery: product.delivery,
      deliveryDate: product.deliveryDate,
      discountGalec: product.discountGalec,
      discountPromo: product.discountPromo,
      box: product?.box || '',
      deee: product?.deee || '',
      tax: product?.tax || '',
      gift: product?.gift || '',
      punch: product?.punch || '',
      discount: product?.discount || '',
    });

    if (product.discount) {
      setCustomerBenefit('discount');
    } else if (product.gift) {
      setCustomerBenefit('gift');
    } else if (product.punch) {
      setCustomerBenefit('punch');
    }
  
    setInit(true);

  }, [productsReducer.product]);

  useEffect(() => {
    if (
      formData?.ref &&
      formData?.gencod &&
      formData?.description &&
      formData?.type &&
      formData?.packing &&
      formData?.price &&
      formData?.pricePABrut &&
      formData?.tva &&
      formData?.delivery &&
      formData?.deliveryDate &&
      formData?.discountGalec &&
      formData?.discountPromo &&
      (
        (customerBenefit === 'gift' && formData?.gift)
        || (customerBenefit === 'punch' && formData?.punch)
        || (customerBenefit === 'discount' && formData?.discount)
      )
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

  }, [formData, customerBenefit]);

  const submit = async () => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;

    if (!isValid) return;
    
    if (product) {
      const data = { ...formData };
      const response = await _putProduct({
        _id: product._id,
        ...data
      });
    } else {
      const data = { ...formData };
      const response = await _postProduct({
        company: companyReducer.company._id,
        ...data,
      });
      if (response.message === 'product saved') history.push('/products-provider');
    }
  };

  const handleChangeImage = async (img) => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;

    _resetErrorProducts();
    if (!product) {
      const toBase64 = (file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(null);
      });

      const base64 = await toBase64(img);
      if (base64) handleChange(base64, null, "image");
    } else {
      if (product.image) await deleteFile(product.image._id);
      const response = await uploadFile(img);
      const data = await response.json();
      if (data.message === 'file saved') {
        _putProduct({
          _id: product._id,
          ...formData,
          image: data.files[0]._id,
        })
      }
    }
  };

  const handleDeleteImage = async () => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;
    _resetErrorProducts();
    if (product.image) await deleteFile(product.image._id);
    _putProduct({
      _id: product._id,
      ...formData,
      image: null,
    });
  };

  const handleChange = (val, error, field) => {

    _resetErrorProducts();
    let updatedData = { ...formData };
    if (error) {
      _.unset(updatedData, field, val);
    } else {
      _.set(updatedData, field, val);
    }
    setFormData(updatedData)
  };

  const handleDeleteProduct = async () => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;
    const response = await _deleteProduct(product._id);
    if (response.status === 200 ) history.push('/products-provider');
  };

  const handleCustomerBenefit = (benefit) => {
    if (product && (product.status === 'valid' || product.status === 'refused')) return;

    _resetErrorProducts();

    let updatedData = { ...formData };
    setCustomerBenefit(benefit);

    _.set(updatedData, 'discount', '');
    _.set(updatedData, 'punch', '');
    _.set(updatedData, 'gift', '');

    setFormData(updatedData)
  };

  const errorMessage = useMemo(() => {
    let message = error;
    if (error === 'This product gencod is already in use.') {
      message = 'Ce gencod est déjà utilisé.';
    }
    return message;
  }, [error]);

  const defaultImageValue = useMemo(() => {
    if (!product?.image) return null;
    const token = (localStorage.getItem('token') || '').replace('JWT ', '');
    const uri = `${process.env.REACT_APP_API_URL}/files/${product.image.path.replace('upload/','')}`;
    return uri;
  }, [product?.image]);

  return (
    <div className={styles.formProduct}>
      <div className="content large">
        <div className={styles.header}>
          <h1>Ajout d'un nouveau produit</h1>
          {(
            !product ||
            product?.status && ['pending', 'correction'].includes(product.status)
          ) && (
            <button
              className={!isValid ? "disabled" : ''}
              onClick={() => submit()}
            >
              {product ? 'Modifier' : 'Ajouter'}
              {isLoading && (
                <div className={styles.loader}></div>
              )}
            </button>
          )}
        </div>
        {errorMessage && (
          <p className={styles.error}>{errorMessage}</p>
        )}
        <NavLink to="/products-provider">
            <button>Retour</button>
          </NavLink>
        {((!params.id && init) || (params.id && product && init)) && (
          <div className={styles.form}>
            <div className={styles.info}>
              <div className={styles.row}>
              <h4>Info</h4>
              {product && (
                <p className={`${styles.status} ${styles[product.status]}`}>
                  {product.status === 'pending' && 'En attente'}
                  {product.status === 'valid' && 'Validée'}
                  {product.status === 'refused' && 'Refusée'}
                  {product.status === 'correction' && 'À corriger'}
                </p>
              )}
              </div>
              <div className={styles.row}>
                <InputField
                  id='ref'
                  defaultValue={formData.ref || ""}
                  title="Nom du produit"
                  placeholder=""
                  type="string"
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "ref")}
                />
              </div>
              <div className={styles.row}>
                <InputField
                  id='brand'
                  defaultValue={formData.brand || ""}
                  title="Marque"
                  placeholder=""
                  type="string"
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "brand")}
                />
                <InputField
                  id='gencod'
                  defaultValue={formData.gencod || ""}
                  title="Gencod"
                  placeholder=""
                  type="string"
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "gencod")}
                />
              </div>
              <div className={styles.row}>
                <div>
                  <label>
                    Image d'illustration
                    <TooltipInfo text={
                      `L'image du produit doit être au format .png, .jpg ou .jpeg et avoir un poids maximum de 1Mo.`
                    }/>
                  </label>
                  <InputImage
                    defaultValue={defaultImageValue}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={handleChangeImage}
                    handleDelete={handleDeleteImage}
                    accept='image/png, image/jpeg'
                  />
                </div>
              </div>
              <div className={styles.row}>
                <InputField
                  id='description'
                  defaultValue={formData.description || ""}
                  title="Designation du produit"
                  placeholder=""
                  type="string"
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "description")}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.selectDelivery}>
                  <label>Typo*</label>
                  <Select
                    onChange={(val) => {
                      handleChange(val.value, null, "type");
                    }}
                    options={optionsType}
                    value={!formData?.type ? [] : optionsType.filter((d) => d.value === formData?.type)}
                    isSearchable={false}
                    styles={selectStyle}
                    isDisabled={product && (product.status === 'valid' || product.status === 'refused')}
                  />
                </div>
                <InputField
                  id='packing'
                  defaultValue={formData.packing || ""}
                  title="Colisage"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "packing")}
                />
              </div>
              <div className={styles.row}>
                <div>
                  <label>Détails box</label>
                  <textarea
                    rows="5" cols="63"
                    value={formData.box || ""}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    onChange={(e) => {
                      handleChange(e.target.value, null, "box");
                    }}
                  />
                  <p className={styles.sub}>
                    Indiquez Gencod, libellé, colisage, PA
                  </p>
                </div>
              </div>
              <div className={styles.row}>
                <InputField
                  id='codelec'
                  defaultValue={formData.codelec || ""}
                  title="CODELEC"
                  placeholder=""
                  type="string"
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "codelec")}
                />
              </div>
            </div>
            <div className={styles.delivery}>
              <h4>Livraison</h4>
              <div className={styles.row}>
                <div className={styles.selectDelivery}>
                  <label>
                    Livraison*
                    <TooltipInfo text={
                      `Choisir un seul mode de livraison pour tous vos produits afin de laisser le franco réalisable`
                    }/>
                  </label>
                  <Select
                    onChange={(val) => {
                      handleChange(val.value, null, "delivery");
                    }}
                    options={optionsDelivery}
                    value={!formData?.delivery ? [] : optionsDelivery.filter((d) => d.value === formData?.delivery)}
                    isSearchable={false}
                    styles={selectStyle}
                    isDisabled={product && (product.status === 'valid' || product.status === 'refused')}
                  />
                </div>
                <InputField
                  id='deliveryDate'
                  defaultValue={formData.deliveryDate || ""}
                  title="Semaine de livraison"
                  placeholder=""
                  type="string"
                  required={true}
                  handleChange={(val, error) => handleChange(val, error, "deliveryDate")}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                />
              </div>
            </div>
            <div className={styles.price}>
              <h4>Tarif</h4>
              <div className={styles.row}>
                <InputField
                  id='price'
                  defaultValue={formData.price || ""}
                  title="Prix net salon"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "price")}
                />
                <InputField
                  id='pricePA'
                  defaultValue={formData.pricePA || ""}
                  title="PA permanent (si permanent)"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "pricePA")}
                />
              </div>
              <div className={styles.row}>
                <InputField
                  id='pricePABrut'
                  defaultValue={formData.pricePABrut || ""}
                  title="PA BRUT"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "pricePABrut")}
                />
                <InputField
                  id='tva'
                  defaultValue={formData.tva || ""}
                  title="TVA (en %)"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "tva")}
                />
              </div>
              <div className={styles.row}>
                <InputField
                  id='deee'
                  defaultValue={formData.deee || ""}
                  title="DEEE"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "deee")}
                />
                <InputField
                  id='tax'
                  defaultValue={formData.tax || ""}
                  title="Autres taxes"
                  placeholder=""
                  type="number"
                  allowEmpty={true}
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "tax")}
                />
              </div>
              <div className={styles.row}>
                <InputField
                  id='discountGalec'
                  defaultValue={formData.discountGalec || ""}
                  title="Remise GALEC"
                  placeholder=""
                  type="string"
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "discountGalec")}
                />
                <InputField
                  id='discountPromo'
                  defaultValue={formData.discountPromo || ""}
                  title="Remise promo"
                  placeholder=""
                  type="string"
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "discountPromo")}
                />
              </div>
              <div className={styles.more}>
                <label>Avantage*</label>
                <ul className={product && (product.status === 'valid' || product.status === 'refused') ? styles.disabled : ''}>
                  <li>
                    <div
                      onClick={() => handleCustomerBenefit('gift')}
                      className={customerBenefit === 'gift' ? `${styles.circle} ${styles.selected}` : styles.circle}
                    >
                      <img src={IconGift} alt="cadeau" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('gift')}>
                      Cadeau
                    </p>
                  </li>
                  <li>
                    <div
                      className={customerBenefit === 'punch' ? `${styles.circle} ${styles.selected}` : styles.circle}
                      onClick={() => handleCustomerBenefit('punch')}
                    >
                      <img src={IconPunch} alt="offre cout de poing" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('punch')}>
                      Coup de poing
                    </p>
                  </li>
                  <li>
                    <div
                      className={customerBenefit === 'discount' ? `${styles.circle} ${styles.selected}` : styles.circle}
                      onClick={() => handleCustomerBenefit('discount')}
                    >
                      <img src={IconDiscount} alt="promotion" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('discount')}>
                      Promotion
                    </p>
                  </li>
                </ul>
                {customerBenefit === 'gift' && (
                  <InputField
                    id='gift'
                    defaultValue={formData.gift || ""}
                    title="Cadeau"
                    placeholder=""
                    type="string"
                    required={true}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "gift")}
                  />
                )}
                {customerBenefit === 'punch' && (
                  <InputField
                    id='punch'
                    defaultValue={formData.punch || ""}
                    title="Coup de poing"
                    placeholder=""
                    type="string"
                    required={true}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "punch")}
                  />
                )}
                {customerBenefit === 'discount' && (
                  <InputField
                    id='discount'
                    defaultValue={formData.discount || ""}
                    title="Pourcentage de réduction"
                    placeholder=""
                    type="number"
                    allowEmpty={true}
                    required={true}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "discount")}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {product && ['pending', 'correction'].includes(product.status) && (
          <div className={styles.delete}>
            <p onClick={() => handleDeleteProduct()}>
              <MdDeleteForever size={20} />
              <span>Supprimer l'article</span>
            </p>
          </div>
        )}
      </div>

    </div>
  )
};

export default EditProduct;

import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import _ from 'lodash';
import axios from 'axios';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';

import { API_URL } from '../../actions/index';

import InputField from '../partials/form_fields/InputField';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';

export default function ResetPassword() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();
  const [validatedPassword, setValidatedPassword] = useState(false);
  const [message, setMessage] = useState();
  const [hidePassword, setHidePassword] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (formData.password
      && formData.passwordConfirm
      && formData.password === formData.passwordConfirm
      && /(?=.*\d).{6,}/.test(formData.password)
      && !validatedPassword
    ) {
      setValidatedPassword(true);
    } else if (validatedPassword) {
      setValidatedPassword(false);
    }
  }, [formData]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        history.push('/');
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  function handleChangeField(val, field) {
    const updatedData = _.cloneDeep(formData);
    _.set(updatedData, field, val);
    setFormData(updatedData);
  }

  function RequestResetPassword() {
    let token = history.location.pathname.replace('/reset-password/', '');
    const requestUrl = `${API_URL}/auth/reset-password/${token}`;

    axios.post(requestUrl, { password: formData.password })
      .then((response) => {
        setError();
        setMessage('Votre mot de passe à été changé, vous allez être redirigé vers la page de connexion');
      })
      .catch((error) => {
        setMessage();
        setError({ message: error.response.data.error });
      });
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (validatedPassword) {
      RequestResetPassword();
    }
  }

  return (
    <div className="page reset-password">
      <div className="content">
        <div className="left">
        </div>
        <div className="form-login" onKeyPress={handlePressEnter}>
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h1>{process.env.REACT_APP_NAME}</h1>
          <h2>Réinitialiser mot de passe</h2>
          <div className="field-icon">
            <InputField
              value=""
              title=""
              placeholder="Saissisez votre nouveau mot de passe"
              type={hidePassword ? "password" : "text"}
              required
              handleChange={(val) => handleChangeField(val, 'password')}
            />
            { hidePassword 
              ? <GrFormViewHide className="icon" onClick={() => setHidePassword(false)} size={24} /> 
              : <GrFormView className="icon" onClick={() => setHidePassword(true)} size={24} />
            }
          </div>
          <div className="field-icon">
            <InputField
              value=""
              title=""
              placeholder="Confirmez votre mot de nouveau passe"
              type={hidePassword ? "password" : "text"}
              required
              handleChange={(val) => handleChangeField(val, 'passwordConfirm')}
            />
            { hidePassword
              ? <GrFormViewHide className="icon" onClick={() => setHidePassword(false)} size={24} /> 
              : <GrFormView className="icon" onClick={() => setHidePassword(true)} size={24} />
            }
          </div>
          <div className="centered">
            <p><span className="sub">Le mot de passe doit contenir aux moins 6 caractères et un 1 chiffre</span></p>
          </div>
          <div className="centered">
            <button className={validatedPassword ? '' : 'disabled'} onClick={RequestResetPassword}>Valider</button>
          </div>
          {error
            ? <p className="warn">{error.message}</p> : null
          }
          {message
            ? <p className="succeed">{message}</p> : null
          }
          <div className="centered">
            <NavLink to="/">Login</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

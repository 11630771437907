import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalContext } from '../../../../GlobalContext';
import imgProviderSubscribe from '../../../../../assets/images/provider-stand-subscribe.svg'
import styles from './confirm-subscribe.module.scss';

const ConfirmSubscribe = ({ history }) => {
  const [context, dispatch] = useGlobalContext();
  const { companyReducer } = context;

  useEffect(() => {
    if(!companyReducer.submitted){
      history.push("/subscribe");
    }
  }, [companyReducer.submitte]);

  useEffect(() => () => {
    if(companyReducer.submitted){
      dispatch({type : "COMPANY_RESET_SUBMITTED"});
    }
  },[]);

  return (
    <div className={styles.confirmSubscribe}>
      <h3>Vous êtes bien inscrit !</h3>
      <img src={imgProviderSubscribe} alt="Vous êtes bien inscrit !"/>
      <NavLink to="/stand/form/">
        <button>Créer mon stand</button>
      </NavLink>
    </div>
  )
};

export default ConfirmSubscribe;

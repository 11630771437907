import React, { useMemo, useEffect } from "react";
import { useGlobalContext } from './GlobalContext'
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import translations from "../assets/translations/translation.json";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getUser } from '../actions/auth'
import { getCompanyList } from '../actions/company'
import useQuery from "../customHooks/useQuery";

import HandleRole from './HandleRole'
import Redirect from './pages/Redirect'

import Register  from "./pages/Register";
import RegisterConfirmation  from "./pages/RegisterConfirmation";

//MAGASIN
import StoreSwitch from './pages/Store/StoreSwitch'
import ListProviders from './pages/Store/ListProviders/ListProviders'
import Orders from './pages/Store/Orders/Orders'
import Order from './pages/Store/Orders/Order';
import Badges from './pages/Store/Badges/Badges';
import Programme from './pages/Store/Programme/Programme';
import Avis from './pages/Avis/Avis';
import Plan from './pages/Plan/Plan';
import ScanProvider from "./pages/Store/ScanProvider";

import QuizzSwitch from './pages/Store/Quizz/QuizzSwitch';
import ValidateOnMobile from './pages/Store/Orders/ValidateOnMobile';
import ProductsSwitch from './pages/Store/Products/ProductsSwitch';

//FOURNISSEUR
import Subscribe from './pages/Provider/Subscription/Subscribe';
import ConfirmSubscribe from './pages/Provider/Subscription/ConfirmSubscribe';
import Contributors from './pages/Provider/Contributors/Contributors';
import StandForm from './pages/Provider/Stand';
import ProductsProvider from './pages/Provider/ProductsProvider/ProductsProvider';
import Visits from './pages/Provider/Visits';

import Animations from './pages/Animations/Animations';

import Informations from './pages/Informations/Informations';
import Trophy from './pages/Trophy/Trophy';
import Salon from './pages/Salon/Salon';
import Montage from './pages/Montage/Montage';

import CGU from './pages/CGU';

import ResetPassword from './Auth/ResetPassword';
import ForgotPassword from './Auth/ForgotPassword';

import Login from './Auth/Login'
import NetworkIndicator from "./partials/NetworkIndicator";

import IconClose from '../assets/images/icons/icon-close.svg';
import AddProduct from "./pages/Provider/EditProduct";

const OrderReadOnly = ({ props }) => <Order readOnly {...props} />

const ContainerSwitch = (props) => {
  const [context, dispatch] = useGlobalContext();
  const { authReducer, showRoomReducer } = context;
  const query = useQuery();
  const history = useHistory();
  const { search } = useLocation();
  const { exhibition } = authReducer;
  const { formData: showroom } = showRoomReducer;

  // ACTIONS
  const _getUser = () => getUser(dispatch);
  const _getCompanyList = () => getCompanyList(dispatch);

  useEffect(() => {
    props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" }
      ],
      translation: translations,
      options: { renderToStaticMarkup }
    });
  }, []);

  // HANDLE SET EXHIBITION 
  // VIA QUERY PARAMS
  useEffect(() => {
    const exhibition = query.get('exhibition');
    if (exhibition) {
      const queryParams = new URLSearchParams(search);
      queryParams.delete('exhibition');
      history.replace({ search: queryParams.toString() });
      localStorage.setItem("exhibition");
    }
  }, [query]);

  useEffect(() => {
    let id = localStorage.getItem("id");
    let token = localStorage.getItem("token");
    let company = localStorage.getItem("company");


    if (!authReducer.user &&
      (id && token && company)
    ) {
      _getUser()
      _getCompanyList()
    }

  }, [authReducer.user]);

  const isStandDisabled = useMemo(() => {
    let isDisabled = false;
    if ((typeof exhibition?.showroomOption?.displayShowroom === 'boolean' && exhibition?.showroomOption?.displayShowroom === false)
    || (typeof showroom?.isEditable === 'boolean' && showroom?.isEditable === false)
    ) isDisabled = true;
    return isDisabled;
  }, [showroom?.isEditable, exhibition?.showroomOption?.displayShowroom]);

  return useMemo(() => {
    return (
      <>
        <NetworkIndicator />
        {context.themeReducer.reglementQuizz && <ReglementQuizz />}
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" render={(props) => <Register {...props} />} />
          <Route exact path="/register-confirmation" render={(props) => <RegisterConfirmation {...props} />} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/subscribe" render={
            () => <HandleRole companyType="fournisseur"><Subscribe {...props} /></HandleRole>
          } />
          <Route exact path="/confirm-subscribe" render={
            () => <HandleRole companyType="fournisseur"><ConfirmSubscribe {...props} /></HandleRole>
          } />
          <Route exact path="/contributors" render={
            () => <HandleRole companyType="fournisseur"><Contributors {...props} /></HandleRole>
          } />
          {(!isStandDisabled) && <Route exact path="/stand/:step/:part?" render={
            (props) => <HandleRole companyType="fournisseur"><StandForm {...props} /></HandleRole>
          } />}
          {/* <Route exact path="/animations" render={
            () => <HandleRole companyType="fournisseur"><Animations {...props} /></HandleRole>
          } />
          <Route exact path="/montage" render={
            () => <HandleRole companyType="fournisseur"><Montage {...props} /></HandleRole>
          } />
          */}
          <Route exact path="/products-provider" render={
            () => <HandleRole companyType="fournisseur"><ProductsProvider {...props} /></HandleRole>
          } />
          <Route exact path="/edit-product-provider/:id?" render={
            () => <HandleRole companyType="fournisseur"><AddProduct {...props} /></HandleRole>
          } />
          <Route exact path="/visits" render={
            () => <HandleRole companyType="fournisseur"><Visits {...props} /></HandleRole>
          } />
          <Route exact path="/store/:page?/:subpage?" render={
            () => <HandleRole companyType="magasin"><StoreSwitch {...props} /></HandleRole>
          } />
          <Route exact path="/products/:page?" render={
            () => <HandleRole companyType="magasin"><ProductsSwitch {...props} /></HandleRole>
          } />
          <Route exact path="/orders" render={
            () => <HandleRole companyType="magasin"><Orders {...props} /></HandleRole>
          } />
          <Route exact path="/order/detail/:id" render={
            () => <HandleRole companyType="magasin"><OrderReadOnly {...props} /></HandleRole>
          } />
          <Route exact path="/order/:id" render={
            () => <HandleRole companyType="magasin"><Order {...props} /></HandleRole>
          } />
          <Route exact path="/validate-order/:id" render={
            () => <HandleRole companyType="magasin"><ValidateOnMobile {...props} /></HandleRole>
          } />
          <Route exact path="/scan-provider" render={
            () => <HandleRole companyType="magasin"><ScanProvider {...props} /></HandleRole>
          } />
          {/* <Route exact path="/list-providers" render={
            () => <HandleRole companyType="magasin"><ListProviders {...props} /></HandleRole>
          } />
          <Route exact path="/programme/:page?" render={
            () => <HandleRole companyType="magasin"><Programme {...props} /></HandleRole>
          } />
          */}
          <Route exact path="/badges" render={
            () => <HandleRole><Badges {...props} /></HandleRole>
          } /> 
          {/*
          <Route exact path="/quizz/:page?/:subpage?" render={
            () => <HandleRole companyType="magasin"><QuizzSwitch {...props} /></HandleRole>
          } />
          */}
          <Route exact path="/plan" render={
            () => <HandleRole ><Plan {...props} /></HandleRole>
          } />
          <Route exact path="/avis/:page?" render={
            () => <HandleRole ><Avis {...props} /></HandleRole>
          } />

          <Route exact path="/trophy" render={
            () => <HandleRole companyType="magasin"><Trophy {...props} /></HandleRole>
          } />
          <Route exact path="/informations/:page?" render={
            () => <HandleRole><Informations {...props} /></HandleRole>
          } />
          <Route exact path="/salon" render={
            () => <HandleRole><Salon {...props} /></HandleRole>
          } />
          <Route exact path="/cgu" component={CGU} />
          <Route exact path="/redirect" component={Redirect} />
        </Switch>
      </>
    )
    // eslint-disable-next-line
  }, [
    isStandDisabled,
    props.location.pathname,
    context.themeReducer.reglementQuizz,
  ])


}

const ReglementQuizz = () => {
  const dispatch = useGlobalContext()[1]

  function close() {
    dispatch({
      type: "MODAL_REGLEMENT_QUIZZ",
      payload: false
    })
  }

  return (
    <div className="container-reglement">
      <div className="overlay" onClick={close}></div>
      <div className="modal">
        <img onClick={close} src={IconClose} alt="close réglement" />
        <h3>Règlement du jeu</h3>
        <p>
          Proin euismod metus vitae fringilla ullamcorper. Integer vulputate blandit est, sed placerat enim pulvinar eget. Sed tristique vehicula elit elementum venenatis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce non tortor blandit, mattis nibh in, mattis risus. Vestibulum placerat, urna vitae vestibulum vulputate, nibh elit suscipit justo, id sollicitudin leo erat at purus. Duis bibendum sollicitudin justo. Sed vitae ipsum massa. Vestibulum quis ex sapien. Morbi turpis metus, cursus ac leo vitae, tincidunt pellentesque libero.
        </p>
        <p>
          Nulla hendrerit, metus a luctus malesuada, ipsum leo maximus nulla, interdum laoreet urna enim ut turpis. Donec eu rutrum diam. Quisque a velit eget arcu malesuada faucibus sit amet a mauris. Etiam pulvinar eros sit amet risus hendrerit, sed feugiat massa eleifend. Phasellus felis augue, rutrum eu sollicitudin sed, blandit et elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed aliquet urna eros, eget tristique neque vehicula ac. Fusce nunc sapien, bibendum eget condimentum sed, scelerisque vel elit. In hac habitasse platea dictumst.
        </p>
      </div>
    </div>
  )
}

export default withLocalize(ContainerSwitch);

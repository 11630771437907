const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius : "12px",
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: state.isDisabled ? "#eaebef" : '#B2B2B2',
    outline : 'none',
    boxShadow: state.isFocused ? 0 : 0,
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : provided.backgroundColor,
    '&:hover': {
       border: state.isFocused ? "solid 1px #B2B2B2" : "solid 1px #B2B2B2"
    }
  }),
  valueContainer: (provided, state ) =>{
    return {
      ...provided,
      height: "38px"
    }
  },
  menuList: (provided, state ) =>{
    return {
      ...provided,
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },
  menu: (provided, state) => ({
    ...provided,
    borderRadius : "12px",
    border : "solid 1px #B2B2B2",
    overflow : "hidden",
    marginTop: "3px",
    boxShadow: state.isFocused ? 0 : 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily : "Montserrat-Regular",
    padding : "0px 12px",
    fontSize : "14px",
    lineHeight: "38px"
  }),
  noOptionsMessage : (provided, state) => ({
    ...provided,
    fontFamily : "Montserrat-Regular",
    padding : "5px 12px",
    fontSize : "14px",
    height: "38px",
    lineHeight: "28px"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize : "14px",
    fontFamily : "Montserrat-Regular",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontSize : "14px",
    fontFamily : "Montserrat-Regular"
  }),
  multiValueRemove: (provided, state) =>({
  ...provided,
  cursor:'pointer',
    '&:hover': {
      color : "#FF8900",
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : "14px",
    fontFamily : "Montserrat-Regular"
  })
}
export default selectStyle;

import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url, enabled = true, exhibition) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!enabled) return () => {};
    setLoading(true);
    setData(null);
    setError(null);
    const source = axios.CancelToken.source();

    const headers = {
      'Content-Type': 'application/json',
    };

    if (exhibition) headers.exhibition = exhibition;

    const get = async () => {
      try {
        const response = await axios.get(url, {
          headers,
          cancelToken: source.token,
        });
        setLoading(false);
        if (response.data) setData(response.data);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    };
    get();
    return () => {
      source.cancel();
    };
  }, [enabled, url]);

  return { data, isLoading: loading, error };
};

export default useFetch;

import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useGlobalContext } from '../../GlobalContext'
import stand from '../../../assets/images/picto-stand.svg';

import styles from './recapitulatif.module.scss';

export default function Recapitulatif({ wasNegociated, ...props }){
  const history = useHistory()
  const [ context ] = useGlobalContext();
  const { authReducer, showRoomReducer } = context;
  const [ validation , setValidation ] = useState({})
  const { exhibition } = authReducer;

  // VALIDATE
  useEffect(() => {
    if(props.isLoading) return;

    const obj = {};
    if (
      exhibition?.showroomOption?.displayShowroomSize &&
      !showRoomReducer?.formData?.dimension
    ) {
      obj.dimension = "Vous n'avez pas indiqué les dimensions du stand"
    }

    setValidation(obj);

  }, [
    props.isLoading,
    exhibition?.showroomOption?.displayShowroomSize,
  ]);

  useEffect(()=>{
    if(showRoomReducer.submitted){
      history.push('/stand/submit-succeed')
    }
  },[showRoomReducer.submitted]);


  return (
    <> 
      <div className={`step ${styles.recapitulatif}`}>
        <p className={`section-title ${styles.sectionTitle}`}>Récapitulatif</p>
        <section>
          <div className={styles.dimension}>
            <div className={styles["info-dimension"]}>
              {!exhibition?.showroomOption?.displayShowroomSize && !validation.dimension && (
                <img className={styles.stand} src={stand} alt="stand" />
              )}
              { showRoomReducer.formData?.dimension ? (
                  <>
                  <p>Dimension validée<br/> (en m) <span className={styles["hide-on-mobile"]}>:</span></p>
                  <p className={styles.dimension}>{showRoomReducer.formData?.dimension}</p>
                </>
              ) :
              <p className={`warn ${styles.warn}`}>{validation.dimension}</p>}
            </div>
          </div>
          {showRoomReducer?.formData?.negotiatedPrice && (
            <div className={styles.negotiatedPrice}>
              <p>Prix négocié</p>
              <p>{showRoomReducer.formData?.negotiatedPrice} €</p>
            </div>
          )}
          <div className={styles["recap-options"]}>
            {(showRoomReducer.formData?.option?.countChair 
              || (showRoomReducer.formData?.option?.chair && typeof showRoomReducer.formData?.option?.chair === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countChair || showRoomReducer.formData?.option?.chair}
                {(showRoomReducer.formData.option.countChair || showRoomReducer.formData?.option?.chair) > 1 ?
                  " chaises." : " chaise."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.countTable 
              || (showRoomReducer.formData?.option?.table && typeof showRoomReducer.formData?.option?.table === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countTable || showRoomReducer.formData?.option?.table}
                {(showRoomReducer.formData.option.countTable || showRoomReducer.formData?.option?.table) > 1 ?
                  " tables." : " table."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.fridgeLength) ? 
            (
              <p>
                Longueur de vitrines réfrigérées en mètres :
                {` ${showRoomReducer.formData.option.fridgeLength}`}
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.countFridge 
              || (showRoomReducer.formData?.option?.fridge && typeof showRoomReducer.formData?.option?.fridge === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countFridge || showRoomReducer.formData?.option?.fridge}
                {(showRoomReducer.formData.option.countFridge || showRoomReducer.formData?.option?.fridge) > 1 ?
                  " vitrines réfrigérées." : " vitrine réfrigérée."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.power && showRoomReducer.formData?.option?.powerQuantity) ?
              <p>
                {showRoomReducer.formData?.option?.powerQuantity > 1 
                  ? `Vous avez besoin de ${showRoomReducer.formData.option.powerQuantity}KW.`
                  : 'Vous avez besoin de 1KW.'
                }
              </p>
            : null}
          </div>
        </section>
      </div>
    </>
  )

}

import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import useFormInput from '../../../customHooks/useFormInput'

const formats = {
  'phone' : /^(\d{2}\s){4}\d{2}$/,
  'email': /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i
}

const InputField = (props) => {
  const [ error , setError ] = useState(false);
  const [ mounted, setMounted ] = useState(false);
  const [ inputValue, setInputValue ] = useState();

  const obj = useFormInput(
    props.value ? props.value : "",
    props.placeholder,
    props.type,
    props.required,
    (props.type !== 'number' || props.allowEmpty) ? '' : 0,
  )

  useEffect(()=>{
    if(props.defaultValue){
      obj.setValue(props.defaultValue)
    }
  },[])

  let inputAttributes = obj.DOMAttribute

  if(props.id){
    inputAttributes.id = props.id
    inputAttributes.key = props.id
  }

  if(props.autocomplete){
    inputAttributes.autoComplete = props.autocomplete
  }

  useEffect(()=>{

    if(!mounted){
      setMounted(true)
      return;
    }

    if(!props.handleChange) return;

    let value = inputAttributes.value;
    if(value === inputValue) return;

    if(props.format){
      let regex = formats[props.format];
      if(!regex.test(value)){
        props.handleChange(value,true);
      }else{
        props.handleChange(value,false);
      }
    }else if(props.required){
      
      if(props.value === ""){
        props.handleChange(value,true);
      }else{
        props.handleChange(value,false);
      }
    }else{
      props.handleChange(value,false);
    }

    setInputValue(value)


  },[inputAttributes.value,inputValue]);


  function handleBlur(value){

    if(props.textTransform){
      let value = handleTextTransform(inputAttributes.value)
      obj.setValue(value)
    }

    if(props.format){
      let regex = formats[props.format]
      setError(!regex.test(inputAttributes.value))
    }

  }

  function handleTextTransform(value){
    let valTransformed;
    switch (props.textTransform) {
      case "lowercase":
        valTransformed = value.toLowerCase()
        break;
      case "uppercase":
        valTransformed = value.toUpperCase()
        break;
      case "capitalized":
        let val = value
        valTransformed = val.charAt(0).toUpperCase() + val.slice(1)
        break;
      default:
        valTransformed = value
    }
    return valTransformed
  }

  return (
    <>
      { props.loading ?
        <>
          <Skeleton variant="text" width="250px" style={{ marginTop: 10 }} />
          <Skeleton height={52} width="100%"
            style={{
             maxWidth: 450,
             marginBottom: 10
            }}
          />
        </> :
        <div className={error ? "container-input error" : "container-input"}>
          <label>
            {props.title}
            {props.title && props.required ?
              <span className="required">*</span> : null
            }
          </label>
          { props.maskNumber ?
            <NumberFormat
              {...inputAttributes}
              type={props.format === "phone" ? "tel" : null}
              format={props.maskNumber}
              onBlur={() => handleBlur()}
            /> :
            <input
              {...inputAttributes}
              maxLength={props.maxLength}
              id={props.id}
              disabled={props.disabled}
              onBlur={() => handleBlur()}
            />
          }

        </div>
      }
    </>

  )


}

export default InputField

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink, useParams, useHistory } from "react-router-dom";

import { useGlobalContext } from '../../../GlobalContext';
import { getProductByID, setProductsFilters, resetSelectedProduct } from '../../../../actions/products';
import { createOrder, updateOrder } from '../../../../actions/orders';
import InputNumber from '../../../partials/form_fields/InputNumber';
import Flash from '../../../partials/Flash';
import ImageItem from './ImageItem';
import IconBox from '../../../../assets/images/icons/icon-box-2.svg'
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg'
import IconPunch from '../../../../assets/images/icons/icon-punch.svg'

import { fnFormat } from '../../../../helpers/helpers';
import { API_URL } from '../../../../actions';

export default function Product() {
  const [context, dispatch] = useGlobalContext()
  const authReducer = context.authReducer
  const productsReducer = context.productsReducer
  const params = useParams();
  const history = useHistory()
  const [product, setProduct] = useState()
  const [orderConfirmed, setOrderConfirmed] = useState()

  //ACTIONS
  const _getProductByID = (id) => getProductByID(dispatch, id)
  const _setProductsFilters = (filters) => setProductsFilters(dispatch, filters)
  const _createOrder = (order) => createOrder(dispatch, order)
  const _updateOrder = (order, id) => updateOrder(dispatch, order, id)
  const _resetSelectedProduct = () => resetSelectedProduct(dispatch)

  useEffect(() => {
    _getProductByID(params.id)
  }, [])

  // IF WE LEAVE PRODUCTS LIST
  // FOR AN OTHER PAGE THAN PRODUCT PAGE
  // CLEAR SAVED FILTERS
  useEffect(() => {
    let unlisten = history.listen((location) => {
      if (!/\/products/.test(location.pathname)) {
        _resetSelectedProduct()
        _setProductsFilters({
          search: null,
          sort: {},
          type: null,
          provider: null
        })
      }
    })
    return () => {
      unlisten()
    }
  }, [])


  useEffect(() => {
    if (!productsReducer.orders || !productsReducer.product) return;
    let p = productsReducer.product
    productsReducer.orders.forEach((order) => {
      let orderId = order._id
      let countBy = _.countBy(order.products, "_id")

      for (let id in countBy) {
        if (id === productsReducer.product._id) {
          p = { ...p, orderId, count: countBy[id] }
        }
      }
      if (order.status === "confirmed") {
        if (order.products[0].company._id === productsReducer.product.company._id) {
          setOrderConfirmed(order._id)
        }
      }

    })

    setProduct(p)

  }, [productsReducer.orders, productsReducer.product])

  function handleCountProduct(count) {

    if (orderConfirmed) {
      let r = window.confirm("Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?");
      if (r == true) {
        fnUpdateOrder(orderConfirmed, product, count, () => {
          history.push(`/order/${orderConfirmed}`)
        })
      }
    } else {
      if (product.orderId) {
        fnUpdateOrder(product.orderId, product, count)
      } else {
        fnCreateOrder(product, count)
      }
    }

  }

  function fnUpdateOrder(orderID, product, count, callback) {
    let order = productsReducer.orders.find((order) => order._id === orderID)
    let updateOrder = _.cloneDeep(order)

    updateOrder.products = updateOrder.products.filter((p) => p._id !== product._id)
    updateOrder.products = [...updateOrder.products, ...new Array(count).fill(product, 0, count)]

    let obj = {
      products: updateOrder.products,
      company: authReducer.user.company,
      user: authReducer.user._id,
      status: "pending"
    }

    _updateOrder(obj, orderID).then(() => {
      if (callback) callback()
    })

  }

  function fnCreateOrder(product, count) {
    let arrProducts = new Array(count).fill(product, 0, count);

    let obj = {
      products: arrProducts,
      company: authReducer.user.company,
      user: authReducer.user._id
    }

    _createOrder(obj)

  }

  function confirm(orderId) {
    let r = window.confirm("Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?");
    if (r == true) {
      let obj = {
        status: "pending"
      }
      _updateOrder(obj, orderId).then(() => {
        history.push(`/order/${orderId}`)
      })
    }
  }

  return (
    <div className="product">
      {product &&
        <div className="content large">
          <div className="title-page">
            <h2>{product.ref}</h2>
            <p>{product.segment}</p>
          </div>
          <NavLink to="/products">
            <button>Retour</button>
          </NavLink>
          <div className={"container-product"}>
            <div className="detail-product">
              {product.discount &&
                 <Flash />
              }
              {product.punch &&
                <div className="gift">
                  <img className="punch" src={IconPunch} alt="opération coup de point" />
                </div>
              }
              {product.gift &&
                <div className="gift">
                  <img src={IconGift} alt="cadeau" />
                </div>
              }
              <div className="container-image">
                {product?.image && (
                  <ImageItem
                    key={`image-product-${product.gencod}`}
                    src={`${process.env.REACT_APP_API_URL}/files/${product.image.path.replace('upload/','')}`}
                    alt={product.segment}
                  />
                )}
              </div>
              <section className="first-section">
                <div className="product-qty-mobile">
                  {orderConfirmed && product.count > 0 ?
                    <button className="goto-order secondary" onClick={() => confirm(orderConfirmed)} >Modifier</button> :
                    <>
                      <InputNumber
                        type={product.type}
                        value={product.count ? product.count : 0}
                        handleChange={(count) => handleCountProduct(count, product.price)}
                      />
                    </>
                  }
                </div>
                {product.discount && (
                  <div>
                    <label>Avantage salon :</label>
                    <p className="promo">{product.discount}%</p>
                  </div>
                )}
                {product.gift && (
                  <div className="container-gift">
                    <label>Offre spéciale&nbsp;:&nbsp;</label>
                    <p>{product.gift}</p>
                  </div>
                )}
                {product.punch && (
                  <div className="container-gift">
                    <label>Coup de poing&nbsp;:&nbsp;</label>
                    <p>{product.punch}</p>
                  </div>
                )}

                <div>
                  <label>Prix net salon HT/unité :</label>
                  <p className="product-price">{product.price ? fnFormat(product.price) + "€" : "Non renseigné"}</p>
                </div>
                <div className="product-qty">
                  {orderConfirmed && product.count > 0 ?
                    <button className="goto-order secondary" onClick={() => confirm(orderConfirmed)} >Modifier</button> :
                    <>
                      <InputNumber
                        type={product.type}
                        value={product.count ? product.count : 0}
                        handleChange={(count) => handleCountProduct(count, product.price)}
                      />
                    </>
                  }
                </div>
              </section>
              <section>
                <div>
                  <label>Fournisseur :</label>
                  <p>{product.company.name}</p>
                </div>
                <div>
                  <label>Désignation produit :</label>
                  <p>{product.description}</p>
                </div>
                <div>
                  <label>Marque :</label>
                  <p>{product.brand}</p>
                </div>
              </section>
              <section>
                <div>
                  <label>Livraison :</label>
                  <p>{product.delivery}</p>
                </div>
                <div>
                  <label>Semaine de livraison :</label>
                  <p>{product.deliveryDate}</p>
                </div>
                <div>
                  <label>Gencod :</label>
                  <p>{product.gencod}</p>
                </div>
                {!product.box &&
                  <div className="cell-packing">
                    <label>Colisage&nbsp;:&nbsp;</label>
                    <p>{product.packing}</p>
                  </div>
                }
              </section>
              { product.box && (
                <section className='detail-box'>
                  <div>
                    <label>
                      <img src={IconBox} alt="box" />
                      Détails box&nbsp;:&nbsp;
                    </label>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {product.box}
                    </p>
                  </div>
                </section>
              )}
              <section>
                <div>
                  <label>PA permanent :</label>
                  <p>{product.pricePA ? fnFormat(product.pricePA) + "€" : "---"}</p>
                </div>
                <div>
                  <label>DEEE :</label>
                  <p>{product.deee ? fnFormat(product.deee) + "€" : "---"}</p>
                </div>
              </section>
              <section>
                <div>
                  <label>TVA :</label>
                  <p>{product.tva ? product.tva + "%" : "---"}</p>
                </div>
                <div>
                  <label>Autres taxes :</label>
                  <p>{product.tax ? product.tax + "€" : "---"}</p>
                </div>
              </section>
              <section>
                <div>
                  <label>Typo :</label>
                  <p>{product.type}</p>
                  {/* <p>{product.product}</p> */}
                </div>
                <div>
                  <label>Code article :</label>
                  <p>{product.code}</p>
                </div>
                <div>
                  <label>Numéro de dossier :</label>
                  <p>{product.fileNumber}</p>
                </div>
              </section>
            </div>
            {product.image && (
              <div className='image-desktop'>
                <ImageItem
                  key={`image-desktop-${product.gencod}`}
                  src={`${process.env.REACT_APP_API_URL}/files/${product.image.path.replace('upload/','')}`}
                  alt={product.segment}
                />
              </div>
            )}
          </div>
        </div>
      }

    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash'
import ListProducts from './ListProducts'
import IconBtn from '../../../partials/IconBtn/IconBtn';
import { useGlobalContext } from '../../../GlobalContext'
import { getProductsProvider } from '../../../../actions/products'
import { fnFormat } from '../../../../helpers/helpers.js'

export const downloadProducts = async (id) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      responseType: 'blob',
      exhibition: `${localStorage.getItem('exhibition')}`,
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/product/export/company/${id}`, config);
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'visits.xlsx';
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export const downloadOrders = async (id) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      responseType: 'blob',
      exhibition: `${localStorage.getItem('exhibition')}`,
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/order/export/fournisseur/${id}`, config);
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'visits.xlsx';
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};


export default function ProductsProvider({ history }) {
  const [context, dispatch] = useGlobalContext()
  const authReducer = context.authReducer;
  const productsReducer = context.productsReducer;
  const companyReducer = context.companyReducer;
  const { company } = companyReducer;
  const [products, setProducts] = useState();

  const _getProductsProvider = (id) => getProductsProvider(dispatch, id)

  useEffect(() => {
    if (authReducer.user && authReducer.user?.company) {
      setProducts();
      _getProductsProvider(authReducer.user.company);
    }
  }, [authReducer.user]);

  useEffect(() => {
    if (!productsReducer.isLoading && productsReducer.products) {
      let products = productsReducer.products.filter(d => d.orders.length > 0);
      let countedProducts = productsReducer.products.map(product => {
        let obj = _.cloneDeep(product)
        let count = 0
        product.orders.forEach((order) => {
          if (order.status === "confirmed") {
            let foundProducts = order.products.filter(d => d === product._id)
            count += foundProducts.length
          }
        })
        obj.count = count
        return obj
      })

      setProducts(countedProducts)

    }
  }, [productsReducer.isLoading, productsReducer.products])

  return (
    <>
      <div className="products-provider">
        <div className="content large">
          <div className="container-ca">
            {typeof productsReducer.ca === 'number' &&
              <div className="title-ca">
                <p>Chiffres d'affaire :</p>
                <h2>{fnFormat(productsReducer.ca)}€</h2>
              </div>
            }
            <div className='btn-export'>
              <IconBtn icon='export' handleClick={() => downloadOrders(company._id)} label='Exporter les commandes' />
            </div>
          </div>

          <div className="title-page">
            <h2>Mes produits</h2>
            <div className='btn-export'>
              <IconBtn icon='exportProducts' handleClick={() => downloadProducts(company._id)} label='Exporter les produits validées' />
            </div>
            <NavLink to="/edit-product-provider">
              <button>Ajouter un produit</button>
            </NavLink>
          </div>
          {(products && products.length > 0) && <ListProducts history={history} products={products} />}
        </div>
      </div>
    </>
  )
}
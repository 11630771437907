import { postData, getData } from './index';

import {
  AUTH_LOGIN,
  AUTH_GET_EXHIBITION,
  AUTH_LIST_CONTACTS,
  AUTH_LIST_EXHIBITIONS,
  AUTH_LIST_COMPANIES,
  AUTH_LOGOUT,
  AUTH_GET_USER,
  RESET_ERROR,
  RESET_AUTH,
  ERROR_AUTH,
} from './types';

export async function logout(dispatch) {
  dispatch({ type: AUTH_LOGOUT });
}

export async function resetError(dispatch) {
  dispatch({
    type: RESET_ERROR,
  });
}

export async function resetAuth(dispatch) {
  dispatch({
    type: RESET_AUTH,
  });
}

export async function signIn(dispatch, data) {
  const url = '/auth/login';

  postData(ERROR_AUTH, false, url, dispatch, data).then((response) => {
    if (response.message === 'provide a contact') {
      dispatch({
        type: AUTH_LIST_CONTACTS,
        payload: {
          contacts: response.contacts,
        },
      });
    } else if (response.message === 'provide a company') {
      dispatch({
        type: AUTH_LIST_COMPANIES,
        payload: {
          companies: response.companies,
        },
      });
    } else if (response.message === 'provide an exhibition') {
      dispatch({
        type: AUTH_LIST_EXHIBITIONS,
        payload: {
          exhibitions: response.exhibitions,
        },
      });
    } else {
      dispatch({
        type: AUTH_LOGIN,
        payload: {
          user: response.user,
          token: response.token,
        },
      });
    }
  });
}

export async function getUser(dispatch) {
  const url = '/profile';
  let user;
  await getData(ERROR_AUTH, url, dispatch, true).then((response) => {
    user = response.data.user;
  });

  dispatch({
    type: AUTH_GET_USER,
    payload: user,
  });
}

export async function getExhibition(dispatch, id) {
  const url = `/exhibition/${id}`;
  const response = await getData(ERROR_AUTH, url, dispatch, true);
  const exhibition = response.data.exhibition;
  if (response.status === 200) {
    dispatch({
      type: AUTH_GET_EXHIBITION,
      payload: exhibition,
    });
  }
  return response;
}
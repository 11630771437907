import React, { useMemo } from 'react';
import { NavLink } from "react-router-dom";
import InputNumber from '../../../partials/form_fields/InputNumber'
import { useGlobalContext } from '../../../GlobalContext'
import { setCountProduct } from '../../../../actions/products'

import { fnFormat } from '../../../../helpers/helpers'
import pending  from "../../../../assets/images/icons/status/pending.svg";
import confirmed from "../../../../assets/images/icons/status/valid.svg";
import refused  from "../../../../assets/images/icons/status/refused.svg";

const icons = {
  pending,
  confirmed,
  refused,
}

function renderStatus(product) {
  var label = "Valider";
  if(product?.status === "valid") {
    return <div className="company-status">
      <div className={`icon confirmed`}>
        {icons.confirmed && <img src={icons.confirmed} alt='status'/>}
      </div>
    </div>
  }
  if(product?.status === "correction") {
    return <div className="company-status">
      <div className={`icon pending`}>
        {icons.pending && <img src={icons.pending} alt='status'/>}
      </div>
    </div>
  }
  if(product?.status === "refused") {
    return <div className="company-status">
    <div className={`icon refused`}>
      {icons.refused && <img src={icons.refused} alt='status'/>}
    </div>
  </div>
  }
  return <div className="company-status">
    <div className={`icon pending`}>
      {icons.pending && <img src={icons.pending} alt='status'/>}
    </div>
  </div>

};

export default function LineProduct({product}){
  const dispatch = useGlobalContext()[1];

  return useMemo(()=>{
    return (
      <div>
        <div className={`product-status ${product.status}`}>
          {renderStatus(product)}
        </div>
        <div className="product-ref hide-on-tablet">
          <p>{product.ref}</p>
        </div>
        <div className="product-brand hide-on-tablet">
          <p>{product.brand}</p>
        </div>
        <div className="product-gencod hide-on-tablet">
          <p>{product.gencod}</p>
        </div>
        <div className="product-packing hide-on-tablet">
          <p>{product.packing}</p>
        </div>
        <div className="product-qty hide-on-tablet">
          <p>{product.count*product.packing} UV</p>
        </div>
        <div className="product-price hide-on-tablet">
          <p>{product.price ? fnFormat(product.price)+"€" : "---"}</p>
        </div>
        <div className="product-ref-brand only-on-tablet">
          <p>Réf : {product.ref}</p>
          <p>Gencod : {product.gencod}</p>
          <p>{product.brand}</p>
        </div>
        <div className="product-packing-qty only-on-tablet">
          <p>Qté commandée en colis : {`${product.count*product.packing} UV (${product.count}x${product.packing})`}</p>
          <p>Colisage : {product.packing}</p>
          <p>{product.price ? fnFormat(product.price)+"€" : "---"}</p>

        </div>
      </div>
    )
  },[product])

}

import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../../GlobalContext'
import { setFormCompany } from '../../../../actions/company'

import Formulaire from './Formulaire/Formulaire'

//NOTE : CETTE PAGE N'EST ACCESSIBLE QUE DU CÔTE FOURNISSEUR

export default function Subscribe ({ history }){
  const [ context, dispatch ] = useGlobalContext();
  const authReducer = context.authReducer;
  const companyReducer = context.companyReducer;

  const [ loading , setLoading ] = useState({ isLoading : true});
  //ACTIONS
  const _setFormCompany = (data) => setFormCompany(dispatch,data)

  useEffect(()=>{
    if(!companyReducer.company || companyReducer.company.type !== "fournisseur") return;
    if(authReducer.user &&
      companyReducer.companyList &&
      companyReducer.companyUsers
    ){

      let contacts = [];
      let mainUser = null;

      companyReducer.companyUsers.forEach((user)=>{
        if(user._id !== companyReducer.company.mainUser){
          contacts.push(user)
        }else{
          mainUser = user
        }
      })

      let formData = {
        "id": authReducer.user.company,
        "name": companyReducer.company.name,
        "type": companyReducer.company.type,
        "code": companyReducer.company.code,
        "iddigit": companyReducer.company.iddigit,
        "centrale": companyReducer.company.centrale,
        "products": companyReducer.company.products,
        "domains": companyReducer.company.domains,
        "insurance":{
          "company": companyReducer.company?.insurance?.company,
          "number": companyReducer.company?.insurance?.number
        }, 
        mainUser,
        contacts: contacts,
      }

      _setFormCompany(formData)
      setLoading({ isLoading : false});
    }

  },[
    authReducer.user,
    companyReducer.companyList,
    companyReducer.companyUsers
  ])

  useEffect(()=>{
    return () => {
      _setFormCompany({});
    }
  },[])

  return(
    <>
      <div className="subscribe">
        <div className="content">
          <div className="title-page">
            <h2>Mon inscription</h2>
          </div>
          <Formulaire
            isLoading={loading.isLoading}
            history={history}
          />
        </div>
      </div>
    </>
  )
}

import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import LogoLeclercMobile from '../../assets/images/logo-leclerc-mobile.svg'
import LogoLeclerc from '../../assets/images/logo-leclerc-header.svg'

export default function CGU (){
  const history = useHistory()
  useEffect(()=>{
    var container = document.querySelector('.cgu');
    let scrollTo;
    if(history.location.hash === "#trophy"){
      scrollTo = document.getElementById('trophy');
    }else if(history.location.hash === "#quizz"){
      scrollTo = document.getElementById('quizz');
    }
    if(scrollTo) container.scrollTop = scrollTo.offsetTop+60;

  },[])

  return (
    <div className="cgu">
      <div className="header">
        <div className="logo">
          <img src={LogoLeclercMobile} alt="logo leclerc" />
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h2>{process.env.REACT_APP_NAME}</h2>
        </div>
      </div>
      <div className="content">
        <button onClick={() => history.goBack()}>Retour</button>
        <h2 id="trophy">ELECTION DU PLUS BEAU STAND</h2>
        <p>
          A l’occasion du Salon BT LEC OUEST qui se déroulera les 14 et 15 septembre 2022, il sera le second jour procédé à l’élection du plus stand d’<b>électroménager</b> et du plus beau stand de <b>produits multimédia.</b>
        </p>
        <p>
          Toutes les personnes attachées à des Centres E.LECLERC (salariés ou dirigeants) et porteuse d’un badge peuvent participer à ces deux élections.
        </p>
        <p>
          Le vote s’effectuera électroniquement sur un support informatique dédié et à disposition de tous.
        </p>
        <p>
          Une liste alphabétique des exposants « électroménager » et une liste alphabétique des exposants « multimédia » seront consultables et le vote s’effectuera à l’aide du badge et par un simple clic sur le nom de l’exposant.
        </p>
        <p>
          Le vote est discrétionnaire.
        </p>
        <p>
          Le scrutin sera clos à 15h30 et le décompte effectué ci-après.
        </p>
        <p>
          Les deux vainqueurs se verront attribuer, à titre gratuit, un stand de 6m² pour le Salon BT LEC OUEST 2022.
        </p>
        <p>
          Les règles de ce scrutin seront à disposition de tous à l’accueil du Salon.
        </p>
        <h2 id="quizz">REGLEMENT DES JEUX « QUIZ »</h2>
        <p>
          Deux jeux sont ouverts aux sociétés exploitant un ou plusieurs Centres E.LECLERC et adhérents du BTLEC OUEST.
        </p>
        <p>
          En cas de pluralités de sites d’exploitation (magasins) par une même personne morale (société) le jeu est ouvert pour chacun des magasins.
        </p>
        <p>
          Ces jeux se dérouleront sur les deux jours du Salon BTLEC OUEST, soit les 14 et 15 septembre 2022.
        </p>
        <h3>I - Modalités de participation.</h3>
        <p>
          Chacun des deux jeux sera disponible sur l’application créée à l’occasion du Salon et dont l’accès ou les coordonnées d’accès seront mis à disposition des magasins.
        </p>
        <p>
          Chaque magasin dispose du droit de jouer une fois chacun des deux jours.
        </p>
        <p>
          La participation de chaque magasin aux jeux sera assurée par toutes les personnes physiques y travaillant, disposant du badge d’accès au Salon et qui devra, pour accéder aux jeux, entrer son code magasin et son nom afin d’éviter les participations multiples d’une même personne.
        </p>
        <p>
          Ainsi un magasin dont 6 salariés sont présents et possédant un badge dispose de 6 possibilités de participation au jeux le premier jours de de 6 possibilités de participation au jeux le second jour.
        </p>
        <h3>II – DEFINITION DES JEUX</h3>
        <p>
          Sur les deux jours du Salon se dérouleront deux jeux :
        </p>
        <p>
          - Un QUIZ ELECTROMENAGER<br/>
          - Un QUIZ MULTIMEDIA
        </p>
        <p>
          Chacun de ces jeux comportera des questions en rapport avec le thème du QUIZ et une question subsidiaire de départage.
        </p>
        <h3>RESULTATS :</h3>
        <p>
          Les deux jeux seront clos le second jour à 15h30 et le relevé des réponses effectué dès après.
        </p>
        <p>
          Le gagnant sera le magasin qui aura apporté le plus grand nombre de réponses exactes aux questions.
        </p>
        <p>
          Pour le cas où il existerait des ex aequo le gagnant sera celui qui donnera la réponse la plus proche du chiffre correspondant à la question de départage.
        </p>
        <p>
          Pour le cas où plusieurs réponses à la question de départage présenteraient le même écart par rapport au chiffre constituant l’exacte réponse, le gagnant sera désigné par tirage au sort.
        </p>
        <p>
          Ce tirage au sort sera effectué par Madame Sandrine RICHARD.
        </p>
        <p>
          Le présent règlement des jeux sera disponible, dépourvu des réponses, à l’accueil du Salon.
          Un exemplaire du règlement des jeux pourvu des réponses, et placé sous enveloppe scellée sera détenu par Madame Sandrine RICHARD qui l’ouvrira à l’issue des jeux et le mettra à disposition à l’accueil du Salon.
        </p>
      </div>
    </div>
  )
}

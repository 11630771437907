import React, { useCallback, useRef, useMemo, useState } from "react";
import axios from "axios";
import _ from "lodash";
import InputField from "../../partials/form_fields/InputField";
import SelectItems from "../../partials/form_fields/SelectItems";
import Select from 'react-select';
import selectStyle from "../../partials/form_fields/selectStyle";
import useFetch from "../../../customHooks/useFetch";

import styles from './register.module.scss';

const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;

export const createUser = async (data, exhibition) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/company/request`,
      data,
      {
        method: 'POST',
        headers: {
          "Authorization": localStorage.getItem('token'),
          "Content-Type": "application/json",
          exhibition,
        },
        body: data
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const Register = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({});

  const {
    data: listExhibitions,
  } = useFetch(
    `${process.env.REACT_APP_API_URL}/company/list/public/exhibition`,
    true,
    null,
  );

  const {
    data: listCompany,
  } = useFetch(
    `${process.env.REACT_APP_API_URL}/company/list/public?type=${formData?.type?.toLowerCase()}`,
    !!formData?.exhibition?.value && !!formData.type,
    formData?.exhibition?.value,
  );

  const {
    days = [],
    exhibitions = [],
    companies = [],
    activities = [],
  } = {
    ...(listExhibitions || {}),
    ...(listCompany || {}),
  };

  function handleChange(val, error, field){
    setErrorMessage('');
    let updatedData = {...formData}

    if (field === 'presence' && val === 'Non') {
      _.set(updatedData, 'lunch', null)
    }

    _.set(updatedData, field, val)
    setFormData(updatedData)
  }

  const isValid = useMemo(() => {
    let bool = false;

    if (
      formData?.exhibition
      && formData?.company
      && formData.type === 'Magasin'
      && formData.profile?.lastName
      && formData.profile?.firstName
      && (
        formData.email &&
        emailReg.test(formData.email)
      )
      && formData.profile?.function
      && (formData.profile?.activity || []).length > 0
      && (
        (formData?.presence === 'Oui' && formData.lunch)
        || (formData?.presence === 'Non')
      )
    ) {
      bool = true;
    } else if (
      formData?.exhibition
      && formData?.company
      && formData?.type === 'Fournisseur'
      && formData.profile?.lastName
      && formData.profile?.firstName
      && (
        formData.email &&
        emailReg.test(formData.email)
      )
      && formData.profile?.function
      && (
        (formData?.presence === 'Oui' && formData.lunch)
        || (formData?.presence === 'Non')
      )
    ) {
      bool = true;
    }

    return bool;
  }, [formData]);

  const handleSubmit = useCallback(() => {
    const type = formData.type;
    if (!isValid || !['Magasin', 'Fournisseur'].includes(type)) return;
    setErrorMessage('');

    let payload = {};
    payload.company = formData.company.value;
    payload.user = {
      email: formData.email,
      profile: {
        ...formData.profile,
      },
      presence: formData.presence === 'Oui' ? days.map((d) => d.value) : [],
    };
    
    if (type === 'Magasin') {
      payload.user.profile.activity = (formData?.activity || []).map((d) => d.value);
      //payload.user.lunch = payload.user.presence > 0;
      payload.user.lunch = formData.presence === 'Oui' ? (formData.lunch === 'Oui') : false;
    } else if (type === 'Fournisseur') {
      payload.user.lunch = formData.presence === 'Oui' ? (formData.lunch === 'Oui') : false;
    }

    createUser(payload, formData?.exhibition?.value).then((res) => {
      history.push('/register-confirmation')
    }).catch((responseError) => {
      console.log(responseError);
      let msg = responseError.error;
      if (responseError.error === 'That email address is already in use.') {
        msg = 'Cette email est déjà utilisé.';
      }
      setErrorMessage(msg);
    })
  }, [isValid, formData]);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.header}>
          {!formData.type && 
            <h1>
              Inscription
            </h1>
          }
          {formData.type === 'Magasin' && 
            <h1>
              Inscription en tant que magasin
            </h1>
          }
          {formData.type === 'Fournisseur' && 
            <h1>
              Inscription en tant que fournisseur
            </h1>
          }
          <button
            disabled={!isValid}
            className={!isValid ? styles.disabled : ''}
            onClick={() => handleSubmit()}
          >
            Soumettre mon Inscription
          </button>
        </div>
        {errorMessage && (
          <p className={styles.error}>
            {errorMessage}
          </p>
        )}
        <div className={styles.contentForm}>
          <h2>Société</h2>
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.containerField}>
                <label>Je m'inscris au salon *</label>
                <Select
                  onChange={(val) => handleChange(val, null, "exhibition")}
                  options={exhibitions}
                  value={formData?.exhibition || null}
                  isSearchable
                  placeholder='Salon...'
                  styles={selectStyle}
                />
              </div>
              <div className={styles.containerField}>
                <label>Je suis *</label>
                <SelectItems
                  items={['Fournisseur', 'Magasin']}
                  selectedItems={formData?.type ? formData?.type : null}
                  uniq
                  required
                  handleChange={(val) => handleChange(val, false, "type")}
                />
              </div>
            </div>
            {(formData.exhibition && formData.type) && (
              <div className={styles.row}>
                <div className={styles.containerField}>
                  <label>Société *</label>
                  <Select
                    onChange={(val) => handleChange(val, null, "company")}
                    options={companies}
                    value={formData?.company || null}
                    isSearchable
                    placeholder='Société...'
                    styles={selectStyle}
                  />
                </div>
              </div>
            )}
          </div>
          <h2>Mon compte</h2>
          <div className={(!formData.type || !formData.company) 
              ? `${styles.form} ${styles.disabled}` 
              : styles.form
          }>
            <div className={styles.row}>
              <div className={styles.containerField}>
                <InputField
                  id="lastName"
                  value={formData?.profile?.lastName || ""}
                  title="Nom"
                  placeholder=""
                  type="string"
                  textTransform="uppercase"
                  required={true}
                  handleChange={(val, error) => handleChange(val, error, "profile.lastName")}
                />
              </div>
              <div className={styles.containerField}>
                <InputField
                  id="firstName"
                  value={formData?.profile?.firstName || ""}
                  title="Prénom"
                  placeholder=""
                  type="string"
                  textTransform="capitalized"
                  required={true}
                  handleChange={(val, error) => handleChange(val, error, "profile.firstName")}
                />
              </div>
              <div className={styles.containerField}>
                <InputField
                  id="email"
                  value={formData.email || ""}
                  title="Email"
                  placeholder=""
                  type="string"
                  format="email"
                  required={true}
                  disabled={false}
                  handleChange={(val, error) => handleChange(val, error, "email")}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.containerField}>
                <InputField
                  value={formData?.profile?.phone || ""}
                  title="Téléphone"
                  placeholder=""
                  type=""
                  maskNumber="## ## ## ## ##"
                  format="phone"
                  handleChange={(val, error) => handleChange(val, error, "profile.phone")}
                />
              </div>
              <div className={styles.containerField}>
                <InputField
                  id="function"
                  value={formData?.profile?.function || ""}
                  title="Fonction"
                  placeholder=""
                  type="string"
                  textTransform="uppercase"
                  required
                  maxLength="30"
                  handleChange={(val, error) => handleChange(val, error, "profile.function")}
                />
              </div>
            </div>
            <div className={styles.row}>
              {formData?.type === 'Magasin' && (
                <div className={styles.containerField}>
                  <label>Lieu de vente<span className="required">*</span></label>
                  <Select
                    onChange={(val) => handleChange(val, null, "profile.activity")}
                    options={activities.map((d) => ({ label: d, value: d }))}
                    value={formData?.profile?.activity || []}
                    isMulti
                    isSearchable
                    styles={selectStyle}
                  />
                </div>
              )}
              <div className={styles.containerField}>
                <label>Sera présent sur le salon<span class="required">*</span></label>
                <SelectItems
                  items={['Oui', 'Non']}
                  selectedItems={formData?.presence ? [formData?.presence] : []}
                  uniq={true}
                  handleChange={(val) => handleChange(val,false,"presence")}
                />
              </div>
              {(formData?.presence === 'Oui') && (
                <div className={styles.containerField}>
                  <label>Participera au déjeuner<span class="required">*</span></label>
                  <SelectItems
                    items={['Oui', 'Non']}
                    selectedItems={formData?.lunch ? [formData?.lunch] : []}
                    uniq={true}
                    handleChange={(val) => handleChange(val,false,"lunch")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {errorMessage && 
          <p className={styles.error}>
            {errorMessage}
          </p>
        }
        <button
          disabled={!isValid}
          className={!isValid ? styles.disabled : ''}
          onClick={() => handleSubmit()}
        >
          Soumettre mon Inscription
        </button>
      </div>
    </div>
  )
};

export default Register;
import React, { useState, useEffect } from 'react'
import _ from "lodash";
import { useGlobalContext } from '../../../GlobalContext'
import usePrevious from '../../../../customHooks/usePrevious'

import { updateCompany } from '../../../../actions/company'

import InputField from '../../../partials/form_fields/InputField'

export default function StoreForm({ company }){
  const [ context, dispatch ] = useGlobalContext();;
  const [ formData, setFormData ] = useState();
  const [ companyMessage, setCompanyMessage ] = useState();

  const [ isValidationDisabled , setIsValidationDisabled] = useState(true);
  const companyReducer = context.companyReducer;
  const prevSubmit = usePrevious(companyReducer.submitted);

  //ACTIONS
  const _updateCompany = (data,id) => updateCompany(dispatch,data,id);

  useEffect(() => {
    if(!company) return;
    setFormData({
      centrale : company.centrale,
      acdlec : company.acdlec,
      name : company.name,
      location : {
        city : company.location ? company.location.city : "",
      },
    });
  }, []);

  useEffect(() => {
    if (!prevSubmit && companyReducer.submitted) {
      setCompanyMessage("Changement enregistré")
      let timer = setTimeout(() => {
        setCompanyMessage();
        clearTimeout(timer);
      }, 2000);

      return () => {
        clearTimeout(timer);
      }

    }
  },[companyReducer.submitted]);


  useEffect(() => {
    if(!formData) return;

    if (formData.centrale && formData.acdlec && formData.name &&
      (formData.location && formData.location.city)
    ) {
      setIsValidationDisabled(false);
    } else {
      setIsValidationDisabled(true);
    }

  },[formData]);

  function handleChange(val, error, field){
    let updatedData = {...formData}
    _.set(updatedData, field, val)
    setFormData(updatedData)
  }

  function submit(){
    if (!isValidationDisabled) {
      _updateCompany(formData,company._id);
    }
  }

  return (
    <div className="module-identification">
      <p className="section-title">Identification</p>
      <section className="identification">
        <InputField
          value={(company && company.centrale) ? company.centrale : ''}
          title={"Centrale alimentaire"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"centrale")}
        />
        <InputField
          value={(company && company.acdlec) ? company.acdlec : ''}
          title={"Code ACDLEC"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"acdlec")}
        />
        <InputField
          value={(company && company.name) ? company.name : ''}
          title={"Raison sociale"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"name")}
        />
        <InputField
          id={"city"}
          value={(company && company.location && company.location.city) ?
            company.location.city : ""
          }
          title={"Ville"}
          autocomplete={"disabled"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"location.city")}
        />
        <div className="container-submit">
          <div className="container-feedback">
            <button onClick={submit} className={isValidationDisabled ? "disabled" : ""}>
              Valider
              {companyReducer.isLoading ?
                <div className="loader"></div> : null
              }
            </button>
            {
              companyMessage ? <p className="succeed">{companyMessage}</p> : null
            }
          </div>
        </div>
      </section>
    </div>
  )
}

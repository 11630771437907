import React, { useContext } from "react";
import { useGlobalContext } from '../GlobalContext';
import ModalContext from '../ModalContext';
import IconMail from '../../assets/images/icons/mail_icon.svg';
import IconPhone from '../../assets/images/icons/phone_icon.svg';

export default function ModalSupportContact() {
  const modalContext = useContext(ModalContext);
  const context = useGlobalContext()[0];
  const authReducer = context.authReducer;
  const { exhibition } = authReducer;

  return (
    <>
      { modalContext.isModalOpen ?
        <div className="modal-support">
          <p className="back" onClick={() => modalContext.setIsModalOpen(false)}>Retour</p>
          <h3>Une question ?</h3>
          {exhibition?.contact?.name && (
            <p>Contactez <br/>{exhibition.contact.name}</p>
          )}
          {exhibition?.contact?.tel && (
            <p className="phone">
              <img src={IconPhone} alt="numéro de téléphone" />
              <a href={`tel:${exhibition.contact.tel}`}>{exhibition.contact.tel}</a>
            </p>
          )}
          {exhibition?.contact?.email && (
            <p className="email">
              <img src={IconMail} alt="adresse email" />
              <a href={`mailto:${exhibition.contact.email}`}>
                {exhibition.contact.email}
              </a>
            </p>
          )}

        </div> : null
      }
    </>
  )
}

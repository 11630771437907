import React, { useEffect } from 'react'
import _ from "lodash";
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { useGlobalContext } from '../../../../GlobalContext'
import { deleteCompanyUser } from '../../../../../actions/company'

import IconPencil from '../../../../../assets/images/icons/icon-pencil.svg'
import IconPencilWhite from '../../../../../assets/images/icons/icon-pencil-white.svg'
import IconPencilHover from '../../../../../assets/images/icons/icon-pencil-hover.svg'
import IconCocktail from '../../../../../assets/images/icons/icon-cocktail.svg'
import IconCocktailGrey from '../../../../../assets/images/icons/icon-cocktail-grey.svg'
import DustBinIcon from '../../../../../assets/images/icons/dustbin_icon.svg'
import DustBinIconWhite from '../../../../../assets/images/icons/dustbin_icon-white.svg'
import DustBinIconHover from '../../../../../assets/images/icons/dustbin_icon_hover.svg'

import FormContact from './FormContact'

export default function LineUser({user, isLoading, activities, edit, selectUser, cancel}){
  const [ context, dispatch] = useGlobalContext();
  const companyReducer = context.companyReducer;
  const authReducer = context.authReducer;

  useEffect(() => {
    console.log();
  }, []);

  //ACTIONS
  const _deleteCompanyUser = (id) => deleteCompanyUser(dispatch,id)

  function renderPresenceDays(days, afterwork){
    let arr = []

    let found = companyReducer.companyList.days.filter(opt=>{
      return days.includes(opt.value)
    })

    if(found){
      arr = found.map(d => d.label)
    }

    
    const getDateStr = (date) => {
      const day = format(new Date(date), 'd MMMM', { locale: fr });
      return day;
    }

    return (
      <ul >
        <li className="only-on-tablet"><label>Présence sur le salon :</label></li>
        {companyReducer.companyList.days.map((d, i) => {
          return (
            <li key={'day-'+i} className={_.includes(arr,d.label) ? "day" : "day disabled"}>
              <p>{getDateStr(d.value)}</p>
            </li>
          )
        })}
      </ul>
    )
  }

  function toggleFormContact(){
    if(edit){
      cancel()
    }else{
      selectUser(user._id)
    }
  }

  function deleteUser(){

    _deleteCompanyUser(user._id)

  }

  return (
    <>
    {!edit &&
      <div className={isLoading && isLoading.action === "delete" ? "line-user loading" : "line-user"}>
        <div className="container hide-on-mobile">
          <div><p>{user.profile.firstName} {user.profile.lastName}</p></div>
          <div><p>{user.profile.function}</p></div>
          <div><p>{user.email.split("//")[0]}</p></div>
        </div>
        <div className="presence-days">{renderPresenceDays(user.presence)}</div>
        <div
          className="edit"
        >
          <div className="user only-on-mobile">
            <div><p>{user.profile.firstName} {user.profile.lastName}</p></div>
            <div><p>{user.profile.function}</p></div>
            <div><p>{user.email.split("//")[0]}</p></div>
          </div>
          <div
            className="toggle-edit"
            onClick={toggleFormContact}
          >
            <label className="only-on-desktop">Modification</label>
            <img src={IconPencilHover} className="hide-on-mobile" alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
            <img src={IconPencilHover} className="hide-on-mobile" alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
            <img className="only-on-mobile" src={IconPencilWhite} alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
          </div>
          { (authReducer.user._id !== user._id && user.role === 'Member') && (
            <div
              className="delete"
              onClick={deleteUser}
            >
              <img className="hide-on-mobile" src={DustBinIcon} data-tip="Supprimer" alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
              <img className="hide-on-mobile" src={DustBinIconHover} data-tip="Supprimer" alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
              <img className="only-on-mobile" src={DustBinIconWhite} alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
            </div>
          )}


        </div>
        { isLoading && isLoading.action === "update" ?
          <div className="loader"></div> :
          null
        }

      </div>
    }

    {edit &&
      <FormContact key={"update_"+user._id} isLoading={isLoading} activities={activities} user={user} cancel={cancel}/>
    }

    <ReactTooltip globalEventOff="click" effect="solid" className="custom-tooltip hide-on-tablet" backgroundColor="#005FBF"/>


    </>
  )
}

import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../../../GlobalContext'

import ModuleIdentification from './ModuleIdentification'
import ModuleContacts from './invitations/ModuleContacts'
import SupportContact from '../../../partials/SupportContact'

export default function Inscription(){
  const context = useGlobalContext()[0];
  const companyReducer = context.companyReducer;

  return (
    <>
      <div className={"store-form"}>
        <div className="content large">
          <div className="title-page">
            <h2>Mon inscription</h2>
          </div>
          <ModuleIdentification company={companyReducer.company}/>
          {companyReducer.company ?
            <ModuleContacts users={companyReducer.companyUsers} activities={companyReducer.company.domains}/> : null
          }
        </div>
        <SupportContact pageForm={true}/>
      </div>
    </>
  )
}

import { getData } from './index';

import {
  GET_VISITS,
  RESET_VISITS,
  ERROR_VISITS,
} from './types';

export async function getVisits(dispatch, companyId) {
  const url = '/visit?company=' + companyId;

  let visits;
  await getData(ERROR_VISITS, url, dispatch, true).then((response) => {
    visits = response.data.visits;
  });

  dispatch({
    type: GET_VISITS,
    payload: visits,
  });
}

export async function resetVisits(dispatch) {
  dispatch({
    type: RESET_VISITS,
  });
}
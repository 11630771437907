/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import useDimension from '../../../customHooks/useDimension';
import HeaderMobileTopBar from './HeaderMobileTopBar';
import Tiles from '../Tiles/Tiles';

import LogoLeclercMobile from '../../../assets/images/logo-leclerc-mobile.svg';
import LogoLeclerc from '../../../assets/images/logo-leclerc-header.svg';
import IconArrow from '../../../assets/images/icons/icon-arrow.svg';
import IconArrowDown from '../../../assets/images/icons/icon-arrow-down.svg';
import IconOrders from '../../../assets/images/icons/icon-orders.svg';
import IconOrdersActive from '../../../assets/images/icons/icon-orders-active.svg';
import PlanSalon2 from '../../../assets/images/plan-salon2.png';

import IconArrowDownWhite from '../../../assets/images/icons/icon-arrow-down-white.svg';
import LogoutIconHover from '../../../assets/images/icons/icon-logout-actived.svg';

import { API_URL } from '../../../actions';
import { useGlobalContext } from '../../GlobalContext';
import { logout } from '../../../actions/auth';
import { resetCompany } from '../../../actions/company';

import { getBadge } from '../../../actions/users';

export default function Header(props) {
  const [context, dispatch] = useGlobalContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuSalonOpen, setIsMenuSalonOpen] = useState(false);
  const [isMenuInformationsOpen, setIsMenuInformationsOpen] = useState(false);

  const [isMenuProfileOpen, setIsMenuProfileOpen] = useState(false);
  const [tiles, setTiles] = useState();
  const {
    authReducer,
    companyReducer,
    productsReducer,
    showRoomReducer,
  } = context;

  const { exhibition } = authReducer;
  const { formData: showroom } = showRoomReducer;

  const dimension = useDimension();
  const history = useHistory();

  //ACTIONS
  const _logout = () => logout(dispatch)
  const _resetCompany = () => resetCompany(dispatch)

  useEffect(() => {

    if (dimension.width <= 1144 && history.location.pathname === "/informations/") {
      setIsMenuSalonOpen(true)
    } else {
      setIsMenuSalonOpen(false)
    }

    let unlisten = history.listen((d) => {
      setIsMenuOpen(false)
      setIsMenuProfileOpen(false)
      setIsMenuSalonOpen(false)
    })
    return () => { unlisten() }

  }, [])

  useEffect(() => {
    if (dimension.width > 1144 && isMenuSalonOpen) {
      setIsMenuSalonOpen(false)
    } else if (
      dimension.width <= 1144 && !isMenuSalonOpen &&
      history.location.pathname === "/informations/"
    ) {
      setIsMenuSalonOpen(true)
    }
  }, [dimension.width])

  useEffect(() => {
    if (!companyReducer.company) return;
    let orders = "Mon panier"
    if (productsReducer.orders && productsReducer.orders.length) {
      orders = `Mon panier<br/>(${panierNumber(productsReducer.orders)})`
    }

    // TO MAKE LINK ACTIVE
    // {title : "Mon badge", url : "/", icon : "badge", action : () => actionGetBadge()},

    let arrTiles;
    if (companyReducer.company.type === "magasin") {
      arrTiles = [
        // { title: "Liste des exposants", url: "/list-providers", icon: "stands" },
        // { title: "Animations et<br/>conférences", url: "/programme", icon: "animations" },
        // { title: "Trophées du <br/>plus beau stand", url: "/trophy", icon: "trophy" },
        // {
        //   title: "Le plan", url: "/", icon: null, background: PlanSalon2,
        //   action: () => history.push('/plan')
        // },
        // { title: "Quiz", url: "/quizz", icon: "quizz" },
        // { title: "Questionnaire de<br/>satisfaction", url: "/avis", icon: "avis" }
      ]


      if (exhibition.withProducts && companyReducer.company.domains.length > 0) {
        arrTiles.push({ title: "Offres salon", url: "/products?page=1", icon: "products" });
        arrTiles.push({ title: orders, url: "/orders", icon: "panier" });
      }
      
      arrTiles.push({ title: "Émargement", url: "/scan-provider", icon: "qrCode" });

    } else if (companyReducer.company.type === "fournisseur") {
      arrTiles = [
        // { title: "Informations<br/>pratiques", url: "/informations/", icon: "info" },
        {title : "Mon badge", url : "/", icon : "badge", action : () => actionGetBadge()},
        // { title: "Questionnaire de<br/>satisfaction", url: "/avis", icon: "avis" },
        
      ]

      if (exhibition.withProducts) {
        arrTiles.push({ title: "Mes produits", url: "/products-provider", icon: "panier" });
      }

    }

    setTiles(arrTiles)
  }, [productsReducer.orders])

  function actionGetBadge() {
    getBadge(authReducer.user._id)
  }

  function classNavigation() {
    let profileOpen = isMenuProfileOpen ? "profile-open" : ""
    return isMenuOpen ? "navigation open " + profileOpen : "navigation " + profileOpen
  }

  function closeNavigation() {
    setIsMenuOpen(false)
    setIsMenuProfileOpen(false)
  }

  function toggleMenuSalon(e) {
    e.stopPropagation();
    setIsMenuSalonOpen(!isMenuSalonOpen)
  }

  function toggleMenuInformations(e) {
    e.stopPropagation();
    setIsMenuInformationsOpen(!isMenuInformationsOpen)
  }

  function fnLogout() {
    _logout()
    _resetCompany()
  }

  function renderHeaderTitle() {
    let title = <h2>{process.env.REACT_APP_NAME}</h2>;
    if (dimension.width > 1144) return title;

    let location = history.location.pathname + history.location.hash

    switch (location) {
      case "/subscribe":
      case "/store/inscription":
        title = <h2>Mon inscription</h2>
        break;
      case "/informations/":
      case "/informations/transports":
      case "/informations/hebergements":
      case "/informations/restauration":
        title = <h2>Informations</h2>
        break;
      case "/animations":
      case "/animations#select":
        title = <h2>Animations</h2>
        break;
      case "/animations#animation":
        title = <h2>Animation</h2>
        break;
      case "/animations#pole":
        title = <h2>Pôle  d'expérience</h2>
        break;
      case "/montage":
        title = <h2>Montage</h2>
        break;
      case "/contributors":
        title = <h2>Mes collaborateurs</h2>
        break;
      case "/orders":
        title = <h2>Mon panier</h2>
        break;
      case "/products-provider":
        title = <h2>Mes produits</h2>
        break;
      case "/products":
        title = <h2>Offres salon</h2>
        break;
      case "/stand/form":
      case "/stand/step-2#1":
      case "/stand/step-2#2":
      case "/stand/recapitulatif":
        title = <h2>Mon stand</h2>
        break;
      case "/avis":
      case "/avis/question-1":
      case "/avis/question-2":
      case "/avis/question-3":
      case "/avis/question-4":
      case "/avis/suggestion":
      case "/avis/form":
        title = <h2>Questionnaire de satisfaction</h2>
        break;
      case "/animations-conferences":
        title = <h2>Animations & conférences</h2>
        break;
      case "/list-providers":
        title = <h2>Liste des exposants</h2>
        break;
      case "/trophy":
        title = <h2>Trophées</h2>
        break;
      case "/scan-provider":
        title = <h2>Émargement</h2>
        break;
      default:
        title = <h2>{process.env.REACT_APP_NAME}</h2>
    }
    return title
  }

  function renderClassSalon() {
    let hide = (companyReducer?.company?.type === "fournisseur") ? "hide-on-tablet" : ""
    return isMenuSalonOpen ? "dropdown-salon open " + hide : "dropdown-salon " + hide
  }

  function panierNumber(orders) {
    if (!orders) return
    let numberColis = 0;

    const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.packing);

    orders.forEach((order) => {
      let arrPacking = order.products.map((d) => {
        return isNaN(parseInt(d.packing)) ? 1 : parseInt(d.packing)
      })
      let total = arrPacking.reduce((accumulator, currentValue) => accumulator + currentValue)
      numberColis += total
    })

    if (numberColis > 0) {
      return numberColis
    } else {
      return null
    }
  }

  const isStandDisabled = useMemo(() => {
    let isDisabled = false;
    if (typeof showroom?.isEditable === 'boolean' && showroom?.isEditable === false) isDisabled = true;
    return isDisabled;
  }, [showroom?.isEditable]);

  return (
    <div className="header">
      <NavLink to="/salon">
        <div className="logo">
          <img src={LogoLeclercMobile} alt="logo leclerc" />
          <img src={LogoLeclerc} alt="logo leclerc" />
          {renderHeaderTitle()}
        </div>
      </NavLink>

      <div className={classNavigation()}>
        <HeaderMobileTopBar
          isMenuProfileOpen={isMenuProfileOpen}
          setIsMenuProfileOpen={setIsMenuProfileOpen}
          closeNavigation={closeNavigation}
        />
        {(isMenuOpen && tiles) && <Tiles tiles={tiles} />}

        {!isMenuProfileOpen ?
          <ul>
            {(companyReducer?.company?.type === "magasin") &&
              <>
                {authReducer?.user?.role !== "Member" &&
                  <li className="only-on-tablet"><NavLink to="/store/inscription">Mon inscription</NavLink></li>
                }
                <li className="only-on-tablet"><NavLink to="/badges">Mes badges</NavLink></li>
                <li className={renderClassSalon()}>
                  <a className={history.location.pathname === "/salon" ? "active" : ""} onClick={() => history.push('/salon')} >
                    Le salon
                    {/* <img className="hide-on-tablet" onClick={toggleMenuSalon} src={IconArrowDownWhite} alt={isMenuSalonOpen ? "menu ouvert" : "menu fermé"} />
                    <img className="hide-on-tablet" onClick={toggleMenuSalon} src={IconArrowDown} alt={isMenuSalonOpen ? "menu ouvert" : "menu fermé"} /> */}
                  </a>
                  {/* <ul className="dropdown">
                    <li className="hide-on-tablet"><NavLink to={"/informations/"} >Informations pratiques</NavLink></li>
                    <li className="hide-on-tablet" ><NavLink to={"/plan"} >Plan du salon</NavLink></li>
                  </ul> */}
                </li>

                {/* <li className="only-on-tablet"><a href={API_URL+"/static/Protocole-Sanitaire-Salon-2021.pptx"} target="_blank">Protocole Sanitaire</a></li> */}
                {/* <li className="only-on-tablet"><a href={API_URL + "/static/COMMENT_PREPARER_ET_VALIDER_LES_OFFRES_SALON-magasins.pdf"} target="_blank">Aide offres salon</a></li> */}
              </>
            }
            {(companyReducer.company && companyReducer.company.type === "magasin") &&
              <>
                {(exhibition.withProducts && companyReducer.company.domains.length > 0) &&
                  <li className="hide-on-tablet"><NavLink to={"/products?page=1"} >Offres salon</NavLink></li>
                }
                {/* <li className="only-on-tablet"><NavLink to={"/trophy"} >Trophées du plus beau stand</NavLink></li>
                <li className="dropdown-animations hide-on-tablet">
                  <a className={""}>
                    Animations
                    <img src={IconArrowDownWhite} alt={"menu fermé"} />
                    <img src={IconArrowDown} alt={"menu fermé"} />
                  </a>
                  <ul className="dropdown">
                    // <li><NavLink to={"/quizz"}>Quiz</NavLink></li>
                    <li><NavLink to={"/trophy"} >Trophées du plus beau stand</NavLink></li>
                    <li className="hide-on-tablet"><NavLink to={"/programme"}>Les animations et conférences</NavLink></li>
                  </ul>
                </li>
                */}
                {(exhibition.withProducts && companyReducer.company.domains.length > 0) &&
                  <li className="li-orders hide-on-tablet">
                    <NavLink to={"/orders"}>
                      <img src={IconOrders} alt="Mon panier" />
                      <img src={IconOrdersActive} alt="Mon panier selectionné" />
                      Mon panier
                      {panierNumber(productsReducer.orders) && <> ({panierNumber(productsReducer.orders)})</>}
                    </NavLink>
                  </li>
                }

              </>
            }

            {(companyReducer.company && companyReducer.company.type === "fournisseur") &&
              <>
                <li>
                  <NavLink exact to={"/salon"} >Le salon</NavLink>
                </li>
                {/* <li className="hide-on-tablet"><NavLink to={"/informations/"} >Informations pratiques</NavLink></li> */}
                {(authReducer.user && authReducer.user.role !== "Member") &&
                  <li className="only-on-tablet"><NavLinkWithParams to={"/subscribe"}>Mon inscription</NavLinkWithParams></li>
                }
                {(authReducer.user && authReducer.user.role !== "Member") &&
                  <li className="only-on-tablet"><NavLink to={"/contributors"}>Mes collaborateurs</NavLink></li>
                }
                {(authReducer.user && authReducer.user.role !== "Member") &&
                  <li className="only-on-tablet"><NavLink to={"/visits"}>Mes visites</NavLink></li>
                }
                {exhibition?.showroomOption?.displayShowroomSize &&
                  <li className={`only-on-tablet ${isStandDisabled ? 'inactive' : ''}`}>
                    <NavLinkWithParams to={"/stand/form"}>Mon stand</NavLinkWithParams>
                  </li>
                }
                {/*<li className="only-on-tablet"><NavLink exact to={"/animations#select"} >Mes animations</NavLink></li>
                <li className="only-on-tablet"><NavLink exact to={"/montage"} >Mon montage</NavLink></li> */}
                <li className="only-on-tablet"><NavLink exact to={"/badges"} >Mes badges</NavLink></li>
                {/* <li className="hide-on-tablet"><NavLink exact to={"/avis"} >Questionnaire de satisfaction</NavLink></li> */}
              </>
            }

            <li className="mobile-logout" onClick={fnLogout}>
              <a className="logout" >
                <img src={LogoutIconHover} alt="logout" />
                Déconnexion
              </a>
            </li>

          </ul> : null
        }

      </div>
      <div className="burger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="profile">
        <div>
          <div className="initialed-user">
            {authReducer.user ?
              <p>{authReducer.user.profile.firstName.slice(0, 1) + "." + authReducer.user.profile.lastName.slice(0, 1)}</p>
              : null
            }
          </div>
          {companyReducer.company &&
            <p>
              {companyReducer.company.name}
              <img src={IconArrowDown} alt="menu fermé" />
            </p>
          }
        </div>
        {companyReducer.company && companyReducer.company.type === "magasin" ?
          <ul className="dropdown">
            {authReducer.user && authReducer.user.role !== "Member" ?
              <li><NavLink exact to={"/store/inscription"}>Mon inscription</NavLink></li> : null
            }
            {/* <li><NavLink to="/avis">Questionnaire de satisfaction</NavLink></li> */}
            <li><NavLink to="/badges">Mes badges</NavLink></li>
            {/*// <li><a href={API_URL+"/static/Protocole-Sanitaire-Salon-2021.pptx"} target="_blank">Protocole Sanitaire</a></li>
            <li><a href={API_URL + "/static/COMMENT_PREPARER_ET_VALIDER_LES_OFFRES_SALON-magasins.pdf"} target="_blank">Aide offres salon</a></li> */}
            <li onClick={fnLogout} className="logout">
              <a>
                <img src={IconArrow} alt="Déconnexion" />
                Déconnexion
              </a>
            </li>
          </ul>
          : null
        }
        {companyReducer.company && companyReducer.company.type === "fournisseur" ?
          <ul className="dropdown">
            {(authReducer.user && authReducer.user.role !== "Member") &&
              <li><NavLink to={"/subscribe"}>Mon inscription</NavLink></li>
            }
            {(authReducer.user && authReducer.user.role !== "Member") &&
              <li><NavLink to={"/contributors"}>Mes collaborateurs</NavLink></li>
            }
            {(authReducer.user && authReducer.user.role !== "Member") &&
              <li><NavLink to={"/visits"}>Mes visites</NavLink></li>
            }
            {exhibition?.showroomOption?.displayShowroomSize && <li className={isStandDisabled ? 'inactive' : ''} >
              <NavLinkWithParams to={"/stand/form"}>
                Mon stand
              </NavLinkWithParams>
            </li>}
            {/* <li>
              <NavLink exact to={"/animations"} >
                Mes animations
              </NavLink>
            </li>
            <li>
              <NavLink exact to={"/montage"} >
                Mon montage
              </NavLink>
            </li>
             */}
            {exhibition.withProducts && (
              <li><NavLink to={"/products-provider"} >Mes produits</NavLink></li>
            )}
            <li><NavLink to="/badges">Mes badges</NavLink></li>
            <li onClick={fnLogout} className="logout">
              <a>
                <img src={IconArrow} alt="Déconnexion" />
                Déconnexion
              </a>
            </li>
          </ul>
          : null
        }
      </div>
    </div>
  )
}


const NavLinkWithParams = props => {
  return (
    <NavLink isActive={(_, { pathname }) => {
      let to = props.to.slice(1).split("/")[0];
      let path = pathname.slice(1).split("/")[0];
      return path === to
    }} {...props} />
  )
}

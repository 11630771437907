import React, {
  useState, useEffect, useRef,
} from 'react';
import imgOffline from '../../../assets/images/offline.svg';
import styles from './network-indicator.module.scss';

const NetworkIndicator = () => {
  const [online, setOnline] = useState(navigator.onLine);
  const timer = useRef();

  useEffect(() => {

    const handleOnline = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setOnline(true);
      }, 1000);
    };

    const handleOffline = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setOnline(false);
      }, 1000);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (timer.current) clearTimeout(timer.current);
    }

  }, []);

  return (
    <div
      className={styles.networkIndicator}
      style={online ? { visibility: 'hidden', height: 0, pointerEvents: 'none' } : { }}
    >
      <div className={styles.container}>
        <img src={imgOffline} />
        <h2>La connexion à<br />été perdue</h2>
        <p>Merci de reconnecter votre téléphone à internet.</p>
      </div>
    </div>
  );
}

export default NetworkIndicator
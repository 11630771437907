import React from "react";
import _ from "lodash";
import ModalStandalone from "../../partials/ModalStandalone";
import imgProviderSubscribe from '../../../assets/images/provider-stand-subscribe.svg'

import styles from './register-confirmation.module.scss';

const RegisterConfirmation = ({ history }) => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <h1>
          Votre demande d'inscription<br/>
          est bien envoyée&nbsp;!
        </h1>
        <h4>
          Vous recevrez un mail de confirmation<br/>
          dès que l'équipe aura validé votre demande
        </h4>
        <img src={imgProviderSubscribe} alt="Vous êtes bien inscrit !"/>
        <button onClick={() => history.push('register')}>
          Nouvelle inscription
        </button>
      </div>
    </div>
  )
};

export default RegisterConfirmation;
import { useState, useEffect } from 'react'

export default function useFormInput(
  initialValue,
  placeholder,
  type = "text",
  required = false,
  minValue,
){
  const [value, setValue] = useState(initialValue)
  const [inputType, setInputType] = useState(type === "string" ? "text" : type)

  useEffect(()=>{
    setValue(initialValue)
  },[initialValue]);

  useEffect(()=>{
    setInputType(type)
  },[type])

  function handleChange(e){
    if(e.target.value === "" && inputType === "number"){
      setValue(minValue)
    }else{
      setValue(e.target.value)
    }
  }

  function setType(newType){
    setInputType(newType)
  }

  let objDOMAttribute = {
    value,
    type : inputType,
    placeholder,
    onChange : handleChange,
  }

  if(type === "number"){
    objDOMAttribute.min = 0
  }

  return {
    DOMAttribute : objDOMAttribute,
    required,
    setType : setType,
    setValue : setValue,
  }

}

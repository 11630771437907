import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import QrReader from 'react-qr-reader';
import Lottie from 'react-lottie';
import { useGlobalContext } from '../../../GlobalContext';
import useDimension from "../../../../customHooks/useDimension";
import Loader from '../../../partials/Loader';
import checkMark from './checkMark.json';
import styles from './scan-provider.module.scss';

const scanProvider = async (
  userMagasin,
  userFournisseur,
) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/visit`, {
    method: 'POST',
    headers: {
      "Authorization": localStorage.getItem('token'),
      "exhibition": localStorage.getItem('exhibition'),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userMagasin,
      userFournisseur
    })
  });
  return response;
};

const ScanProvider = ({ history }) => {
  const [context] = useGlobalContext()
  const authReducer = context.authReducer;
  const { user } = authReducer;
  const [validate, setValidate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dimension = useDimension();

  const handleScan = useCallback((data) => {
    if (isLoading) return; 
    if (data) submit(data);
  }, [isLoading]);

  const handleError = (err) => {
    console.error(err);
  };

  const submit = async (providerUserId) => {
    setIsLoading(true);
    const res = await scanProvider(user._id, providerUserId);
    if (res.status === 200) {
      setValidate(true);
    } else {
      setValidate(false);
    }
    setIsLoading(false);
  };

  const reset = () => {
    setIsLoading(false);
    setValidate(null);
  }

  const pageHeight = useMemo(() => {
    let heightHeader = 90;
    if (dimension.width < 1224) {
      heightHeader = 70;
    }
    return dimension.height - heightHeader;
  }, [dimension.width]);

  return (
    <div className={styles.scanProvider} style={{ height : pageHeight }}>
      { validate === null &&
          <div className={styles.scanner}>
            {isLoading && (
              <div className={styles.loader}>
                <Loader/>
              </div>
            )}
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              // style={{ height: pageHeight }}
            />

            <div className={styles.submit}>
              <button onClick={() => history.push('/salon')}>Revenir à l'accueil</button>
            </div>
          </div>
        }
        { validate === true && (
          <div className={styles.success}>
            <Lottie
              options={{
                loop: false,
                autoplay: true, 
                animationData: checkMark,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              eventListeners={[{
                eventName: 'complete',
                callback: () => {
                  const timer = setTimeout(() => {
                    clearInterval(timer);
                    // setValidate(null);
                    // history.push('/salon');
                  }, 1500);
                },
              }]}
              height={280}
              width={280}
            />
            <button onClick={() => {
              setValidate(null);
            }}>Scanner un autre badge</button>
            <button onClick={() => history.push('/salon')}>Revenir à l'accueil</button>

          </div>
        )}
        { validate === false && (
          <div className={styles.failed}>
            <h3>Erreur lors de la validation<br/> de la visite</h3>
            <button onClick={() => reset()}>Ressayer</button>
          </div>
        )}
    </div>
  );
};

export default ScanProvider;
import {
  GET_VISITS,
  RESET_VISITS,
  VISITS_LOADING,
  ERROR_VISITS,
} from "../actions/types";

const initialState = { visits: [],  error: null, isLoading: false };

export default function visitReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VISITS :
      return { ...state, visits : action.payload }
    case RESET_VISITS :
      return { ...state,  visits : [], isLoading: false }
    case VISITS_LOADING :
      return { ...state,  visits : [], isLoading: true }
    case ERROR_VISITS :
      return {...state, error : action.payload.error};
    default:
      return {...state};
  }
}

import React, { useMemo } from 'react';
import InputNumber from '../../../partials/form_fields/InputNumber'
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg'
import IconPunch from '../../../../assets/images/icons/icon-punch.svg'

import { fnFormat } from '../../../../helpers/helpers'

export default function LineProduct({ product, updateOrder, count, readOnly = false }) {

  const handleCountProduct = (count) => {
    updateOrder(product.orderId, product, count)
  }

  return useMemo(() => {
    return (
      <div>
        {product.discount &&
          <div className="discount">
            <p>-{product.discount}<span className="unit">%</span></p>
          </div>
        }
        {product.punch &&
          <div className="discount">
            <img className="punch" src={IconPunch} alt="opération coup de point" />
          </div>
        }
        {product.gift &&
          <div className="discount">
            <img src={IconGift} alt="cadeau" />
          </div>
        }
        <div className="product-type hide-on-tablet">
          <p>{product.product}</p>
        </div>
        <div className="product-ref hide-on-tablet">
          <p>{product.ref}</p>
        </div>
        <div className="product-price">
          <div className="only-on-tablet">
            <span className="only-on-tablet">
              <div className="mobile-segment-ref">
                <p>{product.segment}</p>
                <p>Réf : {product.ref}</p>
              </div>
            </span>
            <p>{fnFormat(product.total)}€</p>
          </div>
          <p className="hide-on-tablet">{fnFormat(product.price)}€</p>
        </div>
        <div className="product-segment hide-on-tablet">
          <p>{product.segment}</p>
        </div>
        <div className="product-code hide-on-tablet">
          <p>{product.gencod}</p>
        </div>
        <div className="product-packing hide-on-tablet">
          <p>{product.packing}</p>
        </div>
        <div className="product-qty">
          <span className="only-on-tablet">
            <div className="product-packing">
              <p>Colisage : {product.packing}</p>
            </div>
          </span>
          <InputNumber
            value={count}
            disabled={readOnly}
            handleChange={(t) => handleCountProduct(t)}
            type={product.type}
          />
        </div>
        <div className={readOnly ? "product-total readonly hide-on-tablet" : "product-total hide-on-tablet"}>
          <p>{fnFormat(product.total)}€</p>
        </div>
      </div>
    )
  }, [product, count])

}

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';
import InputField from '../partials/form_fields/InputField';
import selectStyle from '../partials/form_fields/selectStyle';

import LogoLeclerc from '../../assets/images/logo-leclerc-2.svg';
import IconMail from '../../assets/images/icons/mail_grey_icon.svg';

import { useGlobalContext } from '../GlobalContext';
import { signIn, resetError } from '../../actions/auth';

export default function Login(props) {
  const [context, dispatch] = useGlobalContext();
  const [loginData, setLoginData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [optionsCompanies, setOptionsCompanies] = useState();
  const [optionsExhibitions, setOptionsExhibitions] = useState();

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedExhibition, setSelectedExhibition] = useState();

  const { authReducer } = context;

  // ACTIONS
  const _signIn = (data) => signIn(dispatch, data);
  const _resetError = () => resetError(dispatch);

  useEffect(() => {
    if (loginData.email !== '' && loginData.password !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [loginData.email, loginData.password]);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (
      id && token
      && authReducer.user
      && authReducer.user._id
    ) {
      props.history.push('/redirect');
    }
  }, [authReducer.user]);

  useEffect(() => {
    localStorage.removeItem('exhibition');
  }, []);

  useEffect(() => {
    if (!authReducer.companies) return;
    const options = [];
    authReducer.companies.forEach((company) => {
      options.push({ label: company.name, value: company.id });
    });
    setOptionsCompanies(options);
  }, [authReducer.companies]);

  useEffect(() => {
    if (!authReducer.exhibitions) return;
    const options = [];
    authReducer.exhibitions.forEach((exhibition) => {
      options.push({ label: exhibition.name, value: exhibition._id });
    });
    setOptionsExhibitions(options);
  }, [authReducer.exhibitions]);

  useEffect(() => {
    if (authReducer.error) {
      const timer = setTimeout(() => {
        clearInterval(timer);
        _resetError();
      }, 2500);
      return () => {
        clearInterval(timer);
        _resetError();
      };
    }
  }, [authReducer.error]);

  function handleChange(val, field) {
    if (field === 'company') {
      setSelectedCompany(val);
    } else if (field === 'exhibition') {
      localStorage.setItem('exhibition', val.value);
      setSelectedExhibition(val);
    }

    const obj = { ...loginData };
    obj[field] = val;
    setLoginData(obj);
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (!disabled) {
      const data = _.cloneDeep(loginData);
      if (data.company) {
        data.company = loginData.company.value;
      }
      _signIn(data);
    }
  }

  function login() {
    const data = _.cloneDeep(loginData);
    if (data.company) {
      data.company = loginData.company.value;
    }
    _signIn(data);
  }

  return (
    <div className="page login">
      <div className="content">
        <div className="left">
        </div>
        <div className="form-login" onKeyPress={handlePressEnter}>
          <NavLink to="/">
            <img src={LogoLeclerc} alt="logo leclerc" />
            <h2>Salons SOCAMAINE</h2>
          </NavLink>


          <NavLink to="/register">
            <button>Je m'inscris</button>
          </NavLink>
          <NavLink to="/forgot-password">
            <button>
              Première connexion / mot de passe oublié
            </button>
          </NavLink>

          <div className='separator' />

          <h2>J'ai déjà un compte</h2>

          <div className="field-icon">
            <InputField
              value=""
              placeholder="Saissisez votre adresse email"
              type="email"
              format="email"
              textTransform="lowercase"
              handleChange={(val) => handleChange(val, 'email')}
            />
            <img src={IconMail} alt="adresse email" />
          </div>
          <div className="field-icon">
            <InputField
              value=""
              placeholder="Saissisez votre mot de passe"
              type={hidePassword ? "password" : "text"}
              required
              handleChange={(val) => handleChange(val, 'password')}
            />
            { hidePassword 
              ? <GrFormViewHide className="icon" onClick={() => setHidePassword(false)} size={24} /> 
              : <GrFormView className="icon" onClick={() => setHidePassword(true)} size={24} />
            }
          </div>
          { optionsExhibitions
            ? (
              <div className="field-icon">
                <Select
                  onChange={(val) => handleChange(val, 'exhibition')}
                  options={optionsExhibitions}
                  value={selectedExhibition}
                  isSearchable={false}
                  styles={selectStyle}
                />
              </div>
            ) : null
          }
          { optionsCompanies
            ? (
              <div className="field-icon">
                <Select
                  onChange={(val) => handleChange(val, 'company')}
                  options={optionsCompanies}
                  value={selectedCompany}
                  isSearchable={false}
                  styles={selectStyle}
                />
              </div>
            ) : null
          }

          <div className="centered">
            <button className={disabled ? 'disabled' : ''} onClick={login}>Je me connecte</button>
          </div>

          { authReducer.error
            ? (
              <div className="centered">
                <p className="warn">Erreur d'authentification, veuillez vérifier vos identifiants.</p>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useContext, useMemo } from 'react';
import _ from "lodash";

import { useGlobalContext } from '../../../../GlobalContext';
import { setFormShowroom } from '../../../../../actions/showroom';
import InputField from '../../../../partials/form_fields/InputField';

import SwitchField from '../../../../partials/form_fields/SwitchField';

export default function ListOptions({ handleErrorsOptions, ...props }){
  const [ context, dispatch ] = useGlobalContext();
  const { authReducer, showRoomReducer } = context;
  const { exhibition } = authReducer;
  const [ standiste, setStandiste] = useState({});

  //SWITCH STATE

  const [ table, setTable] = useState(false);
  const [ countTable, setCountTable] = useState(null);

  const [ chair, setChair] = useState(false);
  const [ countChair, setCountChair] = useState(null);

  const [ fridge, setFridge] = useState(false);
  const [ countFridge, setCountFridge] = useState(null);
  const [ valueFidgeLength, setValueFidgeLength] = useState(null);

  const [ power, setPower] = useState(false);
  const [ powerQuantity, setPowerQuantity] = useState(0);

  //ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch,data);

  /// INIT LISTOPTIONS WITH FORMDATA FROM SHOWROOMREDUCER
  useEffect(()=>{
    if (props.isLoading) return;
    let options = showRoomReducer.formData.option ? showRoomReducer.formData.option : {};

    const countChair = options.countChair || options.chair;
    if (countChair && exhibition.showroomOption.chairs.isAvailable) {
      setChair(!!countChair);
      setCountChair(countChair);
    }

    const countTable = options.countTable || options.table;
    if (countTable) {
      setTable(!!countTable);
      setCountTable(countTable);
    }

    if (exhibition.showroomOption.fridge.isAvailable && exhibition.fridgeProvided) {
      const countFridge = options.countFridge || options.fridge;
      if (countFridge) {
        setFridge(!!countFridge);
        setCountFridge(countFridge);
      }
    } else if (exhibition.showroomOption.fridge.isAvailable) {
      const initFridgeLength = options.fridgeLength;
      if (initFridgeLength) {
        setFridge(!!initFridgeLength);
        setValueFidgeLength(initFridgeLength);
      }
    }



    if (exhibition.showroomOption.power.isAvailable && 
      typeof options.power === "boolean"
    ) {
      setPower(options.power);
      const powerQuantity = parseInt(options.powerQuantity, 10);
      setPowerQuantity(!Number.isNaN(powerQuantity) ? powerQuantity : 0);
    }

    if (options.standiste) {
      let dataStandiste = options.standiste;

      setStandiste({
        name : dataStandiste.name ? dataStandiste.name : "",
        email : dataStandiste.email ? dataStandiste.email : "",
        phone : dataStandiste.phone ? dataStandiste.phone : "",
      });
    }

  },[
    exhibition.showroomOption.chairs.isAvailable,
    exhibition.showroomOption.fridge.isAvailable,
    exhibition.showroomOption.power.isAvailable,
    props.isLoading
  ]);

  function handleChange(val,field) {
    if(val === "") return;
    let updatedData = _.cloneDeep(showRoomReducer.formData);

    if (field === "option.chair") {
      if (!val) {
        setCountChair(0);
        delete updatedData.option.countChair;
      }
      setChair(val);
    } else if (field === "option.countChair") {
      setCountChair(val);
    } else if (field === "option.table") {
      if (!val) {
        setCountTable(0);
        delete updatedData.option.countTable;
      }
      setTable(val);
    } else if (field === "option.countTable") {
      setCountTable(val);
    } if(field === "option.fridge") {
      if(!val) {
        setCountFridge(0);
        setValueFidgeLength(0);
        delete updatedData.option.countFridge;
        delete updatedData.option.fridgeLength;
      }
      setFridge(val);
    } else if (field === "option.countFridge") { 
      setCountFridge(val);
    } else if (field === "option.fridgeLength") { 
      setValueFidgeLength(val);
    } else if (field === "option.power") {
      setPower(val);
      if(!val){
        setPowerQuantity(0);
        updatedData.option.powerQuantity = 0;
      }
    } else if (field === "option.powerQuantity") {
      setPowerQuantity(val);
    }
    _.set(updatedData, field, val);
    _setFormShowroom(updatedData);
  }

  const errors = useMemo(() => {
    const showroomOptions = exhibition.showroomOption;
    const errors = {};
    if (
      chair && showroomOptions.chairs.isRequired && showroomOptions.chairs.isAvailable &&
      (parseInt(countChair) === 0 || !countChair)
    ) {
      errors.chair = true;
    }

    if (
      fridge && showroomOptions.fridge.isRequired && showroomOptions.fridge.isAvailable &&
      showroomOptions.fridge.isProvided &&
      (parseInt(countFridge) === 0 || !countFridge)
    ) {
      errors.fridge = true;
    }

    if (
      fridge && showroomOptions.fridge.isRequired && showroomOptions.fridge.isAvailable &&
      !showroomOptions.fridge.isProvided &&
      (parseInt(valueFidgeLength) === 0 || !valueFidgeLength)
    ) {
      errors.fridge = true;
    }

    if (
      power && showroomOptions.power.isRequired && showroomOptions.power.isAvailable &&
      (parseInt(powerQuantity) === 0 || !powerQuantity)
    ) {
      errors.power = true;
    }

    return errors;
  }, [
    exhibition,
    chair,
    countChair,
    fridge,
    countFridge,
    valueFidgeLength,
    power,
    powerQuantity,
  ]);

  useEffect(() => {
    handleErrorsOptions(errors);
  }, [errors]);

  return (
    <div className="list-options">
      <section>
        {exhibition.showroomOption.chairs.isAvailable && (
          <div className="container-switch">
            <SwitchField
              checked={chair}
              handleChange={(val) => handleChange(val,"option.chair")}
              conditional={ chair && (
                <>
                <InputField
                  value={countChair}
                  title={"Nombre de chaises"}
                  placeholder={""}
                  type={"number"}
                  handleChange={(val) => handleChange(val,"option.countChair")}
                />
                   {(showRoomReducer.formData && exhibition.showroomOption.chairs.isRequired &&
                      (parseInt(showRoomReducer.formData.option.countChair) === 0 || !showRoomReducer.formData.option.countChair)
                    )  &&
                      <p className="warn">
                        Veuillez renseigner le nombre de chaises.
                    </p>
                    }
                </>
              )}
            >
              <p>
                Souhaitez vous disposer de chaise&nbsp;? 
                <span className="sub">
                  {` (${exhibition.showroomOption.chairs.price})`} €
                </span>
              </p>
            </SwitchField>
          </div>
        )}
        <div className="container-switch">
          <SwitchField
            checked={table}
            handleChange={(val) => handleChange(val,"option.table")}
            conditional={ table && (
              <InputField
                value={countTable}
                title={"Nombre de tables"}
                placeholder={""}
                type={"number"}
                handleChange={(val) => handleChange(val,"option.countTable")}
              />
            )}
          >
            <p>
              Souhaitez vous disposer de table ? <span className="sub">(1,5m par 0,75m)</span><br/>
            </p>
          </SwitchField>
        </div>
        {exhibition.showroomOption.fridge.isAvailable && (
          <div className="container-switch">
            <SwitchField
              checked={fridge}
              handleChange={(val) => handleChange(val,"option.fridge")}
              conditional={ fridge && (
                <>
                  {exhibition.showroomOption.fridge.isProvided ?
                    <>
                      <InputField
                        value={countFridge}
                        title={"Nombre de vitrines réfrigérées"}
                        placeholder={""}
                        type={"number"}
                        handleChange={(val) => handleChange(val,"option.countFridge")}
                      />
                      {errors.fridge &&
                        <p className="warn">
                          Veuillez renseigner le nombre de vitrine.
                      </p>
                      }
                    </> :
                    <>
                      <p><span className="sub">Attention les vitrines ne sont pas fournies, elles sont à apportées par vos soins.</span></p>
                      <InputField
                        value={valueFidgeLength}
                        title={"Longueur de vitrine(s) en mètres"}
                        placeholder={""}
                        type={"number"}
                        handleChange={(val) => handleChange(val,"option.fridgeLength")}
                      />
                      {errors.fridge &&
                        <p className="warn">
                          Veuillez renseigner la longueur de la vitrine.
                      </p>
                      }
                    </>
                  }
                  
                </>
              )}
            >
              <p>
              
                Souhaitez vous disposer de vitrines réfrigérées {(exhibition.fridgeProvided && 'fournies')} ?
                {!exhibition.fridgeProvided && <span className="sub">{` (${exhibition.showroomOption.fridge.price})`} €</span>}
                {exhibition.fridgeProvided && <span className="sub">(0,6 m)</span>}
                <br/>
              </p>
            </SwitchField>
          </div>
        )}
        {exhibition.showroomOption.power.isAvailable && (
          <div className="container-switch">
            <SwitchField
              checked={power}
              handleChange={(val) => handleChange(val,"option.power")}
              conditional={power ?
                <>
                <InputField
                  value={powerQuantity}
                  title={"Puissance électrique (en KW)"}
                  placeholder={""}
                  type={"number"}
                  handleChange={(val) => handleChange(val,"option.powerQuantity")}
                />
                {errors.power &&
                  <p className="warn">
                    Veuillez renseigner la puissance électrique dont vous avez besoin.
                </p>
                }
                </>
                : null
              }
            >
              <p>
                Branchement électrique (oui / non)
                <span className="sub">
                  {` (${exhibition.showroomOption.power.price})`} €
                </span>
              </p>
            </SwitchField>
          </div>
        )}
      </section>
      <section>
        <div className="standiste">
          <p className="section-title">Information sur le standiste</p>
          <div className="container-inputs">
            <InputField
              value={standiste.name ? standiste.name : ""}
              title={"Nom"}
              placeholder={""}
              type={"string"}
              textTransform={"uppercase"}
              handleChange={(val) => handleChange(val,"option.standiste.name")}
            />
            <InputField
              value={standiste.email ? standiste.email : ""}
              title={"Adresse mail"}
              placeholder={""}
              format={"email"}
              type={"string"}
              handleChange={(val) => handleChange(val,"option.standiste.email")}
            />
            <InputField
              value={standiste.phone ? standiste.phone : ""}
              title={"Téléphone"}
              placeholder={""}
              type={"string"}
              maskNumber={"## ## ## ## ##"}
              format={"phone"}
              handleChange={(val) => handleChange(val,"option.standiste.phone")}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
